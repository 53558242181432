<template>
    <!-- NOTE: readOnly and isDisabled props don't apply if user is ADMIN -->
    <div>
        <!-- DEMANDE DETAILS SECTION -->
        <div class="shadow pa-2 my-3">
            <!-- details -->
            <!-- NOTE:
                - all project methods are in the mixin projectMixin.js
                - all transitions methods are in the mixin transitionsMixin.js
            -->
            <div>
                <v-layout row wrap>
                    <!-- toggles between Qualité, Maintenance and SAV non conformités view -->
                    <v-flex flex xs6>
                        <h2>Détails de la Non-conformité</h2>
                        <v-btn-toggle
                        v-model="toggleMode"
                        mandatory
                        class="pt-1"
                    >
                        <!-- button Qualité view -->
                        <v-btn
                            id="mode-qualite-btn"
                            small
                            :color="toggleMode === 0 ? '#90CAF9' : null "
                            @click="setMode('qualite')"
                        >
                            Qualité
                        </v-btn>

                        <!-- button Maintenance view -->
                        <v-btn
                            id="mode-maintenance-btn"
                            small
                            :color="toggleMode === 1 ? '#90CAF9' : null"
                            @click="setMode('maintenance')"
                        >
                            Maintenance
                        </v-btn>

                        <!-- button SAV view -->
                        <v-btn
                            id="mode-sav-btn"
                            small
                            :color="toggleMode === 2 ? '#90CAF9' : null"
                            @click="setMode('sav')"
                        >
                            SAV
                        </v-btn>
                    </v-btn-toggle>
                    </v-flex>

                    <!-- NC project number -->
                    <v-flex xs6>
                        <div style="text-align: right; margin-right: 10px;">
                            <h3>Numéro du project créé par Syteline</h3>
                            <h4 style="color:green;" v-if="nonConformite.projet">{{ nonConformite.projet }}</h4>
                            <h4 style="color:darkred;" v-else>*Projet inexistant</h4>
                        </div>
                    </v-flex>

                    <v-flex xs6 style="display: inline-flex; height: 27px; margin:7px 0 15px 0;">
                            <div style="width:26px;">
                                <v-checkbox
                                    v-model="nonConformite.isReception"
                                    class="pa-0 ma-0"
                                    :disabled="Boolean(nonConformite.projet)"
                                />
                            </div>
                            <div :style="{'color': nonConformite.projet ? '#a5a1a1' : '#000000', 'padding':'2px 0 0 0', 'font-size': '14px', 'width':'315px'}">Cette non-conformité ne contient pas de # projet</div>
                    </v-flex>

                    <!--  Gestion états  -->
                    <v-flex xs6 style="margin-top:-8px;">
                        <v-btn class="ma-0" style="float:right; padding: 0px 10px; height: 34px;" @click="showHandleStatusDialog = true">Suivi des états</v-btn>
                        <v-btn v-if="nonConformite.projet" class="ma-0" style="float:right; padding: 0px 10px; height: 34px;" @click="showHandleModelUnitDialog = true" >Modifier l'unité et/ou le model</v-btn>
                    </v-flex>

                </v-layout>
            </div>
            <DemandDetails
                ref="demandeDetails"
                :nonConformite="nonConformite"
                titleLabel="Titre"
                descriptionLabel="Description"
                :readOnly="readOnly"
                :isDisabled="isDisabled"
                :showNcParams="true"
                :showSolutionRequired="true"
                :showStatesAndCriticalitySelectBox="false"
                :isGNCQualite="isGNCQualite"
                :isGNCAdmin="isGNCAdmin"
                :isGNCSupervisor="isGNCSupervisor"
                :projectIncludesUnit="projectIncludesUnit"
                :projectMessages="projectMessages"
                :projectErrorMessages="projectErrorMessages"
                :stageUnits="stageUnits"
                :stageModels="stageModels"
                :partNos="partNos"
                :partNosAdd="partNosAdd"
                :projectPartNos="projectPartNos"
                :categories="categories"
                :departments="departments"
                :causes="causes"
                :employeeCause="employeeCause"
                :providerCause="providerCause"
                :employees="employees"
                :providers="providers"
                :newSytelineProjectNumber="newSytelineProjectNumber"
                :isSpinnerLoading="isSpinnerLoading"
                @setProjectInfo="emitSetProjectInfo"
                @changeNoAssemblage="emitChangeNoAssemblage($event)"
                @addNewProject="emitAddNewProject"
                @applyTransition="emitApplyTransition"
                @searchNoAssemblage="emitSearchNoAssemblage($event)"
                @checkUp="emitCheckUp"
                @resolutionMembersList="emitResolutionMembersList($event)"
                @updateGncMessage="emitUpdatedGncMessage($event)"
            />

            <!-- images and files upload -->
            <!-- NOTE:
               - all images methods are in the mixin imagesMixin.js
               - all files methods are in the mixin filesMixin.js
           -->
            <v-layout row wrap>
                <v-flex class="w-100">
                    <h2>Téléchargement d'images et/ou de fichiers</h2>
                </v-flex>

                <!-- files -->
                <v-flex class="w-100">
                    <FilesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        filesOrigin="structure"
                        buttonLabel="Fichiers"
                        :isDisabled="isDisabled"
                        :files="nonConformite.ncFichiers"
                        @uploadFile="emitUploadFile"
                        @removeFile="emitRemoveFile"
                    />
                </v-flex>

                <!-- images -->
                <v-flex class="w-100">
                     <ImagesUpload
                         :showUploadButton="true"
                         :showDeleteButton="true"
                         imagesOrigin="structure"
                         buttonLabel="Images"
                         :isDisabled="isDisabled"
                         :images="imagesStructure"
                         @uploadImage="emitUploadImage"
                         @popUpImage="emitPopUpImage"
                         @removeImage="emitRemoveImage"
                     />
                 </v-flex>
            </v-layout>

            <!-- DIALOGS -->
            <v-dialog
                v-model="showHandleStatusDialog"
                :fullscreen="isFullscreenHandleStatusDialog"
            >
                <v-card class="timeline-dialog">
                    <v-card-title class="pt-1 pl-3 pr-3 mt-1">
                        <v-flex xs6>
                            <h2>Suivi des États de la non-conformité</h2>
                        </v-flex>
                        <v-flex xs6>
                            <v-icon class="ml-2 mt-1 close-btn" @click="showHandleStatusDialog = false">fa fa-times</v-icon>
                            <v-icon class="ml-2 mt-2 fullscreen-btn" style="font-size: 18px;" @click="isFullscreenHandleStatusDialog = !isFullscreenHandleStatusDialog">fa fa-window-restore</v-icon>
                        </v-flex>
                    </v-card-title>
                    <v-card-text class="pt-0 pb-0 pl-1 pr-1">
                        <v-container class="pa-0 ml-2 mr-2">
                            <div class="pl-2 pr-2" style="display:inline-block;width:100%;overflow-y:hidden;overflow-x:hidden;height: 180px;">
                                <ul class="timeline timeline-horizontal">
                                    <li v-if="currentStateName.length === 0" class="timeline-item last-item">
                                        <div class="timeline-badge " style="background-color:white;">
                                            <v-icon color="red" style="margin-bottom: 18px;font-size: 24px;">fa fa-times</v-icon>
                                        </div>
                                        <div class="timeline-panel" style="background-color:red;color:white;">
                                            <div class="timeline-heading">
                                                <h4 class="timeline-title">Non créé</h4>
                                                <p class="mb-0"><small class="text-muted"></small></p>
                                            </div>
                                        </div>
                                    </li>
                                    <li v-else class="timeline-item" v-for="(state, index) in transitionStatesHistory" :key="index"
                                        :class="{ 'last-item': index === transitionStatesHistory.length - 1 }">
                                        <div class="timeline-badge" :style="{'background-color' : state.color}">
                                            <v-icon v-if="index !== transitionStatesHistory.length - 1" small :color="state.txtColor" style="margin-bottom: 18px;">fa fa-check</v-icon>
                                            <v-icon v-else-if="state.name === 'travail_approuve'" small :color="state.txtColor" style="margin-bottom: 18px;">fa fa-check</v-icon>
                                        </div>
                                        <div class="timeline-panel" :style="{'background-color' : state.color}">
                                            <div class="timeline-heading" :style="{'color' : state.txtColor}">
                                                <h4 class="timeline-title">{{ state.labelFr }}</h4>
                                                <p class="mb-0"><small class="text-muted"><v-icon :color="state.txtColor" small>fa fa-clock</v-icon> {{ state.onDate }}</small></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div v-if="currentStateName.length > 0" class="pl-2 pr-2" style="display:inline-block;width:100%;overflow-y:hidden;overflow-x:hidden;">
                                <h3 class="mb-2">Options actuelles de transition</h3>
                                <v-chip v-for="(transition, index) in currentPosibilityTransitions" :key="index" :style="{'background-color':transition.color,'color':transition.txtColor, 'font-weight':'bolder'}">
                                    {{ transition.labelFr }}
                                </v-chip>
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="showHandleModelUnitDialog"
                max-width="800px"
            >
                <v-card>
                    <v-card-title>
                        <v-flex xs6>
                            <h2>Modifier l'unité et/ou le model du projet</h2>
                        </v-flex>
                        <v-flex xs6>
                            <v-icon class="ml-2 mt-1 close-btn" @click="cancelChangeUnitModel">fa fa-times</v-icon>
                        </v-flex>
                    </v-card-title>
                    <v-card-text>
                        <v-layout row wrap>
                            <!-- NC stage unit -->
                            <v-flex xs6>
                                <v-autocomplete
                                    id="nc-unit"
                                    v-model="nonConformite.stageUnitId"
                                    label="Unité"
                                    :items="stageUnits"
                                    item-value="id"
                                    item-text="unit"
                                    @change="changeUnitModel"
                                    :disabled="Boolean(!!nonConformite.stageId && !nonConformite.stageUnitId)"
                                    clearable
                                />
                            </v-flex>

                            <!-- NC stage model -->
                            <v-flex xs6>
                                <v-autocomplete
                                    id="nc-stage"
                                    v-model="nonConformite.stageId"
                                    label="Modèle"
                                    :items="stageModels"
                                    item-value="id"
                                    item-text="proposalModelName"
                                    :disabled="Boolean(!!nonConformite.stageUnitId)"
                                    clearable
                                />
                            </v-flex>
                            <v-flex xs12 style="text-align: center;">
                                <v-btn color="green" @click="modifyProductCode()">Modifier</v-btn>
                                <v-btn color="red" @click="cancelChangeUnitModel()">Annuler</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- APPROVE OR REJECT DEMAND SECTION -->
        <div class="shadow pa-2 my-3">
            <h2>Décision demande / Superviseur</h2>

            <v-layout row wrap v-if="nonConformite.projet === null || tryToCreateProjectFail || nonConformite.projet?.length === 0 && nonConformite.etat === NON_CONFORMITES_STORE.etats.SOLUTION_ACCEPTEE">
                <v-flex xs12 md6>
                    <v-btn style="background-color:black;color:white;" @click="emitAddNewProject()">(&nbsp;<span style="color:pink">Bouton d'urgence</span>&nbsp;)&nbsp;&nbsp;Création projet dans syteline</v-btn>
                    <p class="pl-2 pb-0 mb-0" style="color:red">*Ce bouton apparait lors d'un échec de création de projet après l'abbrobation d'une demande par un superviseur. </p>
                    <p class="pl-2 mt-0 pt-0" style="color:red"> Si le problème persite, contactez rapidement le support informatique de Stageline.</p>
                </v-flex>
            </v-layout>

            <v-layout row wrap v-if="isGNCSupervisor && nonConformite.etat === NON_CONFORMITES_STORE.etats.DEMANDE">
                <!-- reject demand button -->
                <!-- you have to enter a reject reason in the text area below for the button to be enabled -->
                <v-flex xs12 md6>
                    <v-btn
                        color="error"
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_DEMANDE, 'QUALITE_REJECT_DEMAND')"
                        :loading="isLoadingTransition"
                        class="mx-0"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_DEMANDE) || !nonConformite.raisonRefus || isDisabled"
                    >
                        Refuser la demande
                    </v-btn>
                </v-flex>

                <!-- approve demand button -->
                <v-flex xs12 md6>
                    <v-btn
                        @click="emitApplyTransition(nonConformite.solutionRequise ? NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE : NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE_NO_SOLUTION, 'QUALITE_APPROUVED_SUP');"
                        :loading="isLoadingTransition"
                        class="mx-0"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE) || isDisabled"
                        color="success"
                    >
                        Approuver la demande
                    </v-btn>
                </v-flex>

                <!-- rejection reason -->
                <v-flex xs12>
                    <v-textarea
                        height="100"
                        v-model="nonConformite.raisonRefus"
                        label="Raison du refus"
                        :readonly="readOnly"
                    />
                </v-flex>
            </v-layout>
        </div>

        <!-- APPROVE OR REJECT SOLUTION SECTION-->
        <div class="shadow pa-2 my-3" v-if="isGNCQualite">
            <h2>Décision solution / Qualité</h2>

            <v-layout row wrap>
                <!-- reject solution button -->
                <!-- you have to enter an alternate solution in the text area below for the button to be enabled -->
                <v-flex xs4 v-if="!!nonConformite.solutionRequise || nonConformite.etat === NON_CONFORMITES_STORE.etats.ATTENTE_SOLUTION">
                    <v-btn
                        class="mx-0"
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_SOLUTION, 'QUALITE_REJECT')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_SOLUTION) || isDisabled"
                        color="red"
                        dark
                    >
                        Refuser la solution
                    </v-btn>
                </v-flex>

                <!-- approve solution button -->
                <v-flex xs4 v-if="!!nonConformite.solutionRequise || nonConformite.etat === NON_CONFORMITES_STORE.etats.ATTENTE_SOLUTION">
                    <v-btn
                        class="mx-0"
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.ACCEPTER_SOLUTION, 'QUALITE_APPROUVED')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.ACCEPTER_SOLUTION) || isDisabled"
                        color="success"
                    >
                        Approuver la solution
                    </v-btn>
                </v-flex>

                <!-- alternate solution  -->
                <v-flex xs12 v-if="!!nonConformite.solutionRequise">
                    <v-textarea
                        v-model="nonConformite.solutionAlternative"
                        label="Solution alternative"
                        :readonly="readOnly"
                    />
                </v-flex>
            </v-layout>
        </div>

        <!-- SOLUTION DETAILS SECTION -->
        <div class="shadow pa-2 my-3" v-if="isGNCSupervisor">
            <h2>Détails solution / Superviseur</h2>

            <!-- TIME ESTIMATE -->
            <v-layout row wrap>
                <!-- date picker dialog -->
                <v-flex xs12 sm6>
                    Impact délai de production:
                    <v-icon v-show="!showIcons" large class="mr-3 ml-5" @click="setGravite(1)" :color="nonConformite.gravite === 1 ? 'success' : ''">fas fa-thermometer-empty</v-icon>
                    <v-icon v-show="!showIcons" large class="mr-3" @click="setGravite(2)" :color="nonConformite.gravite === 2 ? 'warning' : ''">fas fa-thermometer-half</v-icon>
                    <v-icon v-show="!showIcons" large class="mr-3" @click="setGravite(3)" :color="nonConformite.gravite === 3 ? 'error' : ''">fas fa-thermometer-full</v-icon>
                    <!-- Easter egg :P -->
                    <v-icon v-show="showIcons" medium class="mr-3 ml-5 mt-2" @click="setGravite(1)" :color="nonConformite.gravite === 1 ? 'success' : 'brown'">fas fa-poop</v-icon>
                    <v-icon v-show="showIcons" medium class="mr-3 mt-2" @click="setGravite(2)" :color="nonConformite.gravite === 2 ? 'warning' : 'brown'">fas fa-poop</v-icon>
                    <v-icon v-show="showIcons" medium class="mr-3 mt-2" @click="setGravite(3)" :color="nonConformite.gravite === 3 ? 'error' : 'brown'">fas fa-poop</v-icon>
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <v-dialog
                        ref="dialog"
                        v-model="datePickerDialog"
                        :return-value.sync="nonConformite.dateEcheance"
                        persistent
                        lazy
                        full-width
                        width="290px"
                        :disabled="isDisabled"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="nonConformite.dateEcheance"
                                label="Date d'échéance"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            />
                        </template>

                        <v-date-picker v-model="nonConformite.dateEcheance" scrollable locale="fr-CA">
                            <v-spacer/>

                            <v-btn flat color="primary" @click="datePickerDialog = false">Cancel</v-btn>
                            <v-btn flat color="primary" @click="$refs.dialog.save(nonConformite.dateEcheance)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-flex>
            </v-layout>

            <!-- SOLUTION IMAGES AND FILES -->
            <!-- NOTE:
               - all images methods are in the mixin imagesMixin.js
               - all files methods are in the mixin filesMixin.js
            -->
            <v-layout mt-4 row wrap v-if="isGNCSupervisor">
                <!-- solution images -->
                <v-flex xs6 wrap>
                    <ImagesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        imagesOrigin="solution"
                        label="Téléchargement Images Solution"
                        buttonLabel="Images"
                        :isDisabled="isDisabled"
                        :images="imagesSolution"
                        @uploadImage="emitUploadImageSolution"
                        @popUpImage="emitPopUpImage"
                        @removeImage="emitRemoveImage"
                    />
                </v-flex>

                <!-- solution files -->
                <v-flex xs6 wrap>
                    <FilesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        filesOrigin="solution"
                        label="Téléchargement Fichiers Solution"
                        buttonLabel="Fichiers"
                        :isDisabled="isDisabled"
                        :files="nonConformite.ncFichiersSolution"
                        @uploadFile="emitUploadFileSolution"
                        @removeFile="emitRemoveFile"
                    />
                </v-flex>
            </v-layout>

            <v-layout row wrap v-if="isGNCSupervisor && imagesSolution.length > 0">
                <!-- SUBMIT WORK BUTTON -->
                <v-flex xs6>
                    <v-btn
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.SOUMETTRE_TRAVAIL, 'QUALITE_COMPLETED')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.SOUMETTRE_TRAVAIL) || isDisabled || imagesSolution.length === 0 || !isGNCSupervisor"
                        color="info"
                    >
                        Travail complété
                    </v-btn>
                </v-flex>

                <!-- annuler submit work button -->
                <v-flex xs6>
                    <v-btn
                        v-if="isGNCQualite"
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.ANNULER_SOUMETTRE_TRAVAIL, 'ANNULER_SOUMETTRE_TRAVAIL')"
                        :loading="isLoadingTransition"
                        :disabled="onCancelTravailaApprouveDisabled"
                        style="background-color: blueviolet;"
                        class="white--text"
                    >
                        Annuler Travail complété
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <!-- APPROVE OR REJECT WORK SECTION -->
        <div class="shadow pa-2 my-3" v-if="isGNCQualite">
            <h2>Décision finale / Qualité</h2>

            <v-layout row wrap v-if="isGNCQualite">
                <!-- reject work button -->
                <v-flex xs12 md4>
                    <v-btn
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_TRAVAIL, 'QUALITE_REJECT')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_TRAVAIL) || isDisabled"
                        color="red darken-2"
                        class="white--text"
                    >
                        Solution complétée refusée
                    </v-btn>
                </v-flex>

                <!-- approved work button -->
                <v-flex xs12 md4>
                    <v-btn
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.APPROUVER_TRAVAIL, 'QUALITE_COMPLETED')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.APPROUVER_TRAVAIL) || isDisabled"
                        color="green"
                        class="white--text"
                    >
                        Solution complétée résolue
                    </v-btn>
                </v-flex>

                <!-- annuler approved work button -->
                <v-flex xs12 md4>
                    <v-btn
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.ANNULER_TRAVAIL_APPROUVE, 'ANNULER_TRAVAIL_APPROUVE')"
                        :loading="isLoadingTransition"
                        :disabled="onCancelTravailApprouveDisabled"
                        style="background-color: blueviolet;"
                        class="white--text"
                    >
                        Annuler solution complétée résolue
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <!-- CORRECTIVES ACTIONS SECTION -->
        <div class="shadow pa-2 my-3">
            <!-- correctives actions -->
            <!-- NOTE: all correctives actions methods are in the mixin correctivesActionsMixin.js -->
            <CorrectivesActions
                v-if="isGNCSupervisor"
                :readOnly="readOnly"
                :isDisabled="isDisabled"
                :showAutocomplete="true"
                :showCompletionButtons="isGNCQualite"
                :isCorrectiveActionRequired="nonConformite.actionCorrectiveRequise"
                :proposedCorrectiveAction="nonConformite.actionCorrectiveProposee"
                :isCorrectiveActionCompleted="nonConformite.actionCorrectiveComplete"
                :correctivesActions="correctivesActions"
                @setCorrectiveActionRequired="setCorrectiveActionRequired"
                @setProposedCorrectiveAction="setProposedCorrectiveAction"
                @setCorrectiveActionCompleted="setCorrectiveActionCompleted"
                @insertIntoProposedCorrectiveActions="insertIntoProposedCorrectiveActions"
            />
            <v-layout
                v-if="nonConformite.actionCorrectiveRequise"
                row
                wrap
            >
                <v-flex xs12 class="mt-5">
                    <h2>Utilisateurs en suivi pour action corrective</h2>
                    <NCAbonnements
                        :non-conformite="nonConformite"
                        :items="nonConformite.abonnesActionCorrective"
                        :readonly="readOnly"
                        type="action-corrective"
                        :users="usersActionCorrective"
                        @change="emitAbonnementActionCorrective"
                    />
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    //states and transitions constants
    import {NON_CONFORMITES_STORE, ENTREPRISE_STORE, APPLICATION_STORE} from "@/constants";

    //controls
    import ImagesUpload from '../controls/ImagesUpload';
    import FilesUpload from "../controls/FilesUpload";
    import CorrectivesActions from "../controls/CorrectivesActions";
    import DemandDetails from "../controls/DemandDetails";
    import NCAbonnements from "../controls/NC-Abonnements";

    //mixins
    import correctivesActionsMixin from '../../mixins/correctivesActionsMixin';
    import transitionsMixin from "../../mixins/transitionsMixin";
    import projectMixin from "../../mixins/projectMixin";
    import imagesMixin from "../../mixins/imagesMixin";
    import filesMixin from "../../mixins/filesMixin";
    import entrepriseStore from "../../store/entreprise/entrepriseStore";

    export default {
        name: "CreateQualite",
        props: [
            'nonConformite',
            'isDisabled',
            'readOnly',
            'isLoadingTransition',
            'correctivesActions',
            'isGNCQualite',
            'isGNCSupervisor',
            'isGNCAdmin',
            'employeeListSIGM',
            'imagesStructure',
            'imagesSolution',
            'projectIncludesUnit',
            'projectMessages',
            'projectErrorMessages',
            'stageUnits',
            'stageModels',
            'categories',
            'departments',
            'causes',
            'employeeCause',
            'providerCause',
            'employees',
            'providers',
            'partNos',
            'partNosAdd',
            'projectPartNos',
            'newSytelineProjectNumber',
            'tryToCreateProjectFail',
            'isSpinnerLoading'
        ],
        mixins: [
            correctivesActionsMixin,
            transitionsMixin,
            projectMixin,
            imagesMixin,
            filesMixin,
        ],
        components: {
            NCAbonnements,
            //controls
            ImagesUpload,
            FilesUpload,
            CorrectivesActions,
            DemandDetails,
        },
        created() {
            window.addEventListener('keydown', this.toggleIcons);
            window.addEventListener('keyup', this.keyup);
            this.getNcInfo();
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.toggleIcons);
            window.removeEventListener('keyup', this.keyup);
        },
        computed: {
            ...mapState({
                entrepriseStore: state => state.entrepriseStore,
                loginStore: state => state.loginStore
            }),

            isGNCReception() {
                return Array.isArray(this.loginStore.loggedUser.groups) && !!this.loginStore.loggedUser.groups.find(g => {
                    return g.name === 'gnc_admins'
                });
            },

            usersActionCorrective() {
                return this.entrepriseStore.users.filter(user => {
                    return (user.groups.find(group => group.name === 'gnc_superviseurs' || group.name === 'gnc_qualite' || group.name === 'gnc_admins')) != null;
                });
            },
        },
        data: function() {
            return {
                toggleMode:0,
                showIcons: false,
                kKeyPressed: false,

                //constant to get states and transitions
                NON_CONFORMITES_STORE: NON_CONFORMITES_STORE,

                //time estimate validation rules
                timeEstimateRules: [
                    v => /^[0-9]{0,3}$/.test(v) || !v || 'temps invalide',
                ],

                //date picker dialog
                datePickerDialog: false,

                //States handeling
                showHandleStatusDialog: false,
                isFullscreenHandleStatusDialog: false,
                states: [
                    { name: 'demande',              labelFr:'Nouvelle demande',     color: 'cornflowerblue', txtColor: 'black'},
                    { name: 'demande_rejetee',      labelFr:'Demande rejetée',      color: 'orangered',      txtColor: 'white'},
                    { name: 'attente_solution',     labelFr:'Solution en attente',  color: 'darkgrey',       txtColor: 'black'},
                    { name: 'solution_rejetee',     labelFr:'Solution rejetée',     color: 'orangered',      txtColor: 'white'},
                    { name: 'solution_acceptee',    labelFr:'Travail à faire',      color: 'wheat',          txtColor: 'black'},
                    { name: 'travail_a_approuver',  labelFr:'Travail à approuver',  color: 'gold',           txtColor: 'black'},
                    { name: 'travail_rejete',       labelFr:'Travail rejeté',       color: 'orangered',      txtColor: 'white'},
                    { name: 'travail_approuve',     labelFr:'Travail approuvé',     color: 'limegreen',      txtColor: 'black'},
                    { name: 'fermer',               labelFr:'Demande/Travail fermé',color: 'black',          txtColor: 'white'},
                ],
                transitionStatesHistory:[],
                transitionPosibility:[
                    {
                        fromName:'demande',
                        transitionSet:[
                            { name: 'attente_solution',     labelFr:'Solution en attente',  color: 'darkgrey',       txtColor: 'black'},
                            { name: 'solution_acceptee',    labelFr:'Travail à faire',      color: 'wheat',          txtColor: 'black'},
                            { name: 'demande_rejetee',      labelFr:'Demande rejetée',      color: 'orangered',      txtColor: 'white'}
                        ]
                    },
                    {
                        fromName:'attente_solution',
                        transitionSet:[
                            { name: 'solution_acceptee',    labelFr:'Travail à faire',      color: 'wheat',          txtColor: 'black'},
                            { name: 'solution_rejetee',     labelFr:'Solution rejetée',     color: 'orangered',      txtColor: 'white'},
                        ]
                    },
                    {
                        fromName:'solution_acceptee',
                        transitionSet:[
                            { name: 'travail_a_approuver',  labelFr:'Travail à approuver',  color: 'gold',           txtColor: 'black'},
                            { name: 'solution_rejetee',     labelFr:'Solution rejetée',     color: 'orangered',      txtColor: 'white'},
                            { name: 'attente_solution',     labelFr:'Solution en attente',  color: 'darkgrey',       txtColor: 'black'},
                        ]
                    },
                    {
                        fromName:'travail_rejete',
                        transitionSet:[
                            { name: 'travail_a_approuver',  labelFr:'Travail à approuver',  color: 'gold',           txtColor: 'black'},
                        ]
                    },
                    {
                        fromName:'travail_a_approuver',
                        transitionSet:[
                            { name: 'travail_approuve',     labelFr:'Travail approuvé',     color: 'limegreen',      txtColor: 'black'},
                            { name: 'travail_rejete',       labelFr:'Travail rejeté',       color: 'orangered',      txtColor: 'white'},
                            { name: 'solution_acceptee',    labelFr:'Travail à faire',      color: 'wheat',          txtColor: 'black'},
                        ]
                    },
                    {
                        fromName:'travail_approuve',
                        transitionSet:[
                            { name: 'travail_a_approuver',  labelFr:'Travail à approuver',  color: 'gold',           txtColor: 'black'},
                            { name: 'fermer',               labelFr:'Demande/Travail fermé',color: 'black',          txtColor: 'white'},
                        ]
                    },
                ],
                currentStateName: '',
                currentPosibilityTransitions: [],

                //Unit&Model handeling
                showHandleModelUnitDialog: false,
                currentUnitAndModel: {
                    unitId: this.nonConformite.stageUnitId,
                    stageId: this.nonConformite.stageId
                },

                onCancelTravailApprouveDisabled:true,
                onCancelTravailaApprouveDisabled:true,
            }
        },
        methods: {
            //Qualité, Maintenance or SAV
            setMode(mode) {
                switch(mode){
                    case 'qualite':
                        this.toggleMode = 0;
                        this.nonConformite.showInQualite = true;
                        this.nonConformite.showInMaintenance = false;
                        this.nonConformite.showInSav = false;
                        break;
                    case 'maintenance' :
                        this.toggleMode = 1;
                        this.nonConformite.showInQualite = false;
                        this.nonConformite.showInMaintenance = true;
                        this.nonConformite.showInSav = false;
                        break;
                    case 'sav' :
                        this.toggleMode = 2;
                        this.nonConformite.showInQualite = false;
                        this.nonConformite.showInMaintenance = false;
                        this.nonConformite.showInSav = true;
                        break;
                }
            },
            toggleIcons(event) {
                if (event.ctrlKey && event.key === 'k') {
                    this.kKeyPressed = true;
                    this.showIcons = !this.showIcons;
                }
            },
            keyup(event) { // To ensure K press flag is reset
                if (event.key === 'k') {
                    this.kKeyPressed = false;
                }
            },

            setGravite(gravite) {
                if (!this.readOnly) {
                    this.nonConformite.gravite = gravite;
                }
            },

            //checks if a transition can be applied (returns a boolean)
            canApplyTransition(transition) {
                let retval = false;
                switch (transition) {
                    case NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE:
                        retval = this.isGNCSupervisor && this.nonConformite.etat === NON_CONFORMITES_STORE.etats.DEMANDE;
                        break;
                    case NON_CONFORMITES_STORE.transitions.REJETER_DEMANDE:
                        retval = this.isGNCQualite && this.nonConformite.etat === NON_CONFORMITES_STORE.etats.DEMANDE;
                        break;
                    case NON_CONFORMITES_STORE.transitions.ACCEPTER_SOLUTION:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.ATTENTE_SOLUTION);
                        break;
                    case NON_CONFORMITES_STORE.transitions.REJETER_SOLUTION:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.ATTENTE_SOLUTION);
                        break;
                    case NON_CONFORMITES_STORE.transitions.SOUMETTRE_TRAVAIL:
                        retval = this.isGNCSupervisor && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.SOLUTION_ACCEPTEE || this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_REJETE || this.nonConformite.etat === NON_CONFORMITES_STORE.etats.SOLUTION_REJETEE);
                        break;
                    case NON_CONFORMITES_STORE.transitions.APPROUVER_TRAVAIL:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_A_APPROUVER);
                        break;
                    case NON_CONFORMITES_STORE.transitions.ANNULER_TRAVAIL_APPROUVE:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.APPROUVER_TRAVAIL);
                        break;
                    case NON_CONFORMITES_STORE.transitions.REJETER_TRAVAIL:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_A_APPROUVER);
                        break;
                    default:
                        break;
                }

                return retval;
            },

            emitAbonnementActionCorrective(event) {
                this.$emit('abonnementActionCorrective', event);
            },

            getNcInfo(){
                if(this.nonConformite.etat){
                    this.transitionStatesHistory = [];
                    this.currentStateName = this.nonConformite.etatMachine.label_fr;
                    let refPosibilityList = this.transitionPosibility.find(transition => transition.fromName === this.nonConformite.etatMachine.name);
                    this.currentPosibilityTransitions = refPosibilityList.transitionSet;

                    this.nonConformite.history.forEach((transition)=>{
                        let refState = this.states.find(state => state.name === transition.to);
                        let dateParts = transition.created_at;
                        let dateFormated = dateParts.split('T');

                        let state = {
                            name:refState.name,
                            labelFr:refState.labelFr,
                            color:refState.color,
                            txtColor:refState.txtColor,
                            onDate:dateFormated[0]
                        };
                        this.transitionStatesHistory.push(state);
                    });

                    this.transitionStatesHistory.reverse();

                    this.getCancelState();
                }
            },

            getCancelState(){
                if(this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_APPROUVE){
                    this.onCancelTravailaApprouveDisabled = true;
                    this.onCancelTravailApprouveDisabled = false;
                }
                else if(this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_A_APPROUVER){
                    this.onCancelTravailApprouveDisabled = true;
                    this.onCancelTravailaApprouveDisabled = false;
                }
            },

            //Unit & Model
            changeUnitModel(){
                this.$refs.demandeDetails.getStageModel();
            },
            cancelChangeUnitModel(){
                this.nonConformite.stageUnitId = this.currentUnitAndModel.unitId;
                this.nonConformite.stageId = this.currentUnitAndModel.stageId;
                this.showHandleModelUnitDialog = false;
            },
            modifyProductCode(){
                let message = {
                    type:'',
                    text:''
                };

                if(!this.nonConformite.stageUnitId && !this.nonConformite.stageId){
                    alert("Vous devez remplir soit l'unité et/ou le model!");
                }
                else if(this.nonConformite.stageUnitId === this.currentUnitAndModel.unitId && this.nonConformite.stageId === this.currentUnitAndModel.stageId){
                    alert("Vous devez devez changer l'unité, la surprimer ou changer le modèle afin de sauvegarder!");
                }
                else{
                    let newUnitModel = {
                        stageUnitId:!!this.nonConformite.stageUnitId ? this.nonConformite.stageUnitId : null,
                        stageUnit:!!this.nonConformite.stageUnitId ? this.$store.getters[ENTREPRISE_STORE.getters.GET_STAGE_UNIT_NAME](this.nonConformite.stageUnitId) : null,
                        stageId:!!this.nonConformite.stageId ? this.nonConformite.stageId : null,
                        modelName:!!this.nonConformite.stageId ? this.$store.getters[ENTREPRISE_STORE.getters.GET_STAGE_MODEL_NAME](this.nonConformite.stageId) : null
                    }

                    this.$store.dispatch(ENTREPRISE_STORE.actions.MODIFIER_PRODUCT_CODE_NON_CONFORMITES, { nonConformite: this.nonConformite, newUnitModel: newUnitModel }).then(response => {
                        this.currentUnitAndModel.unitId = response.data.nc.stageUnitId;
                        this.currentUnitAndModel.stageId = response.data.nc.stageId;
                        this.showHandleModelUnitDialog = false;

                        message.type = 'success';
                        message.text = 'Changement de l\'unité et/ou du modèle de la non-conformité avec succès!';
                        message.text += ' Ancien projet description: ' + response.data.oldProjDesc;
                        message.text += '. Nouveau projet description: ' + response.data.newProjDesc;

                        this.$store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE, message);
                    }).catch(error => {
                        message.type = 'error';
                        message.text = 'Erreur lors du changement de l\'unité et/ou du modèle de la non-conformité.';
                        this.$store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE, message);
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
.debug1{
    border:1px solid red;
}
.debug2{
    border:1px solid blue;
}
.debug3{
    border:1px solid #bf00ff;
}

.timeline-dialog{
    height: 340px;
}
.close-btn {
    text-shadow: 1px 1px 3px gray;
    font-size: 26px;
    float:right;
}
.fullscreen-btn {
    text-shadow: 1px 1px 3px gray;
    float:right;
    margin-top: 4px;
}

/* Timeline SASS */
.timeline {
    list-style: none;
    padding: 20px;
    position: relative;
    &:before {
        top: 40px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #000000;
        right: 50%;
        margin-right: -1.5px;
    }
    .timeline-item {
        margin-bottom: 20px;
        position: relative;
        &:before,
        &:after {
            content: "";
            display: table;
        }
        &:after {
            clear: both;
        }
        .timeline-badge {
            color: #fff;
            width: 30px;
            height: 30px;
            line-height: 52px;
            font-size: 22px;
            text-align: center;
            position: absolute;
            top: 18px;
            right: 50%;
            margin-right: -20px;
            background-color: #333;
            border: 3px solid #ffffff;
            z-index: 100;
            border-radius: 50%;
            i,
            .fa,
            .glyphicon {
                top: 2px;
                right: 0px;
            }
            &.black {
                background-color: black;
            }
            &.cornflowerblue {
                background-color: cornflowerblue;
            }
            &.limegreen {
                background-color: limegreen;
            }
            &.darkgrey {
                background-color: darkgrey;
            }
            &.wheat {
                background-color: wheat;
            }
            &.gold {
                background-color: gold;
            }
            &.orangered {
                background-color: orangered;
            }
        }
        .timeline-panel {
            position: relative;
            width: 46%;
            float: right;
            left: 16px;
            border-radius: 2px;
            padding: 10px;
            -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            &:before {
                position: absolute;
                top: 26px;
                left: -16px;
                display: inline-block;
                border-top: 16px solid transparent;
                border-left: 16px solid #555;
                border-right: 0 solid #555;
                border-bottom: 16px solid transparent;
                content: " ";
            }
            .timeline-title {
                margin-top: 0;
                color: inherit;
            }
            .timeline-body > p,
            .timeline-body > ul {
                margin-bottom: 0;
            }
            .timeline-body > p + p {
                margin-top: 5px;
            }
        }
    }
    .timeline-item:last-child {
        &:nth-child(even) {
            float: left;
        }
    }
    .timeline-item:nth-child(even) {
        .timeline-panel {
            float: left;
            left: 16px;
            &:before {
                border-left-width: 0;
                border-right-width: 14px;
                right: -14px;
                left: auto;
            }
        }
    }
}
.timeline-horizontal {
    @extend .timeline;
    list-style: none;
    position: relative;
    padding: 10px 0px 10px 0px;
    display: inline-block;
    &:before {
        height: 3px;
        top: auto;
        bottom: 26px;
        right: 56px;
        left: 0;
        width: 100%;
        margin-bottom: 20px;
    }
    .timeline-item {
        display: table-cell;
        height: 150px;
        width: 250px;
        float: none !important;
        padding-right: 0px;
        padding-left: 60px;
        margin: 0 auto;
        vertical-align: bottom;
        .timeline-panel {
            top: auto;
            bottom: 64px;
            display: inline-block;
            float: none !important;
            left: 0 !important;
            right: 0 !important;
            width: 105%;
            margin-bottom: 10px;
            &:before {
                top: auto;
                bottom: -16px;
                right: 8px !important;
                left: auto;
                border-right: 16px solid transparent !important;
                border-top: 16px solid #000000 !important;
                border-bottom: 0 solid #000000 !important;
                border-left: 16px solid transparent !important;
            }
        }
        &:before,
        &:after {
            display: none;
        }
        .timeline-badge {
            top: auto;
            bottom: 22px;
            right: 20px;
        }
    }
}
</style>
