import $axios from '../../api';
import {ENV} from '../../constants';

const state = {
    departements: [],
    units: [],
    stages: [],
    employes: [],
    fournisseurs: [],
    groupes: [],
    users: [],
    noneGncUsers: [],
    futureProjects: null,
    partNos: [],
    partNosAdd: [],
    showLoading : false,
    showSpinnerLoading : false,
    showSpinnerLoading2 : false,
};

const mutations = {
    addNewUser( state, data){
        state.users.push(data.user);
        state.users.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
    },

    deleteUser( state, data){
        let index = state.users.findIndex( u => u.id == data.user.id);
        state.users.splice(index, 1);
        state.noneGncUsers.push(data.user);
        state.noneGncUsers.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
    },

    setDataDepartementsEntrepriseStore(state, data){
        state.departements = data.departements;
    },

    setDataStageUnitsEntrepriseStore(state, data){
        state.units = data.units;
    },

    setDataStagesEntrepriseStore(state, data){
        state.stages = data.stages;
    },

    setDataEmployeesEntrepriseStore(state, data){
        state.employes = data.map(obj => ({
            ...obj,
            EmpNum: obj.EmpNum.trim(),
        }));

        //state.employes = data.employes;
    },

    setDataSuppliersEntrepriseStore(state, data){
        state.fournisseurs = data;

        //state.fournisseurs = data.suppliers;
    },

    setDataPartNos(state, data){
        state.partNos = data;
    },

    setDataPartNosAdd(state, data){
        state.partNosAdd = data;
    },
    setGroupsEntrepriseStore(state, data){
        state.groupes = data.groups;
    },

    setUsersEntrepriseStore(state, data){
        state.users = data.users;
    },

    setNoneGncUsersEntrepriseStore(state, data){
        state.noneGncUsers = data.users;
    },

    setFutureProjects( state, data){
        state.futureProjects = data;
    },

    replaceUser(state, data){
        let index = state.users.findIndex( user => {
            return user.id === data.user.id;
        });

        state.users[index] = data.user;
    },

    isLoading(state, data){
        state.showLoading = data;
    },

    isSpinnerLoading(state, data){
        state.showSpinnerLoading = data;
    },

    isSpinnerLoading2(state, data){
        state.showSpinnerLoading2 = data;
    },
};

const actions = {
    async addUserToApplication( { commit }, data){
        const user = await $axios.post(`users/application/2/addUser/${data.userId}`, data);
        commit('addNewUser', user.data);
        return user;
    },

    async deleteUser( { commit }, user){
        const deletedUser = await $axios.delete(`users/application/2/deleteUser/${user.id}`);
        commit('deleteUser', deletedUser.data);
        return deletedUser.data;
    },

    //fetches departments list
    fetchDataDepartementsEntrepriseStore({ state, commit, rootState }) {
        $axios.get(`/nc/departements/list`).then( response => {
            commit('setDataDepartementsEntrepriseStore', response.data)
        })
        .catch(function (error) {
        });
    },

    fetchDataProjectNumber({state}, data) {
        return new Promise( (resolve, reject) => {
            $axios.get(`/sigm/project/${data.projectNumber}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    },

    //fetches Syteline
    fetchLastProjectNumberFromSyteline({state, commit}, fromApp) {
        commit('isLoading', true);
        return new Promise( (resolve, reject) => {
            $axios.get(`/erp/last-project-number/${fromApp}`).then(response => {
                resolve(response);
            }).catch(error => {
                commit('isLoading', false);
                reject(error);
            })
        })
    },

    fetchAddNewProjectToSyteline({state, commit}, data) {
        commit('isLoading', true);
        return new Promise( (resolve, reject) => {
            let encode = JSON.stringify(data);
            $axios.post(`/erp/add-project/${encode}`).then(response => {
                commit('isLoading', false);
                resolve(response);
            }).catch(error => {
                commit('isLoading', false);
                reject(error);
            })
        })
    },

    //Add/Update GNC part Project in Syteline/Stageline system
    addUpdatePartInProject({state, commit}, data) {
        commit('isLoading', true);
        return new Promise( (resolve, reject) => {
            $axios.post(`/erp/${data.action}/part`, data).then(response => {
                commit('isLoading', false);
                resolve(response);
            }).catch(error => {
                commit('isLoading', false);
                reject(error);
            })
        })
    },

    //Delete GNC part Project in Syteline/Stageline system
    deletePartInProject({state, commit}, data) {
        commit('isLoading', true);
        return new Promise( (resolve, reject) => {
            $axios.get(`/erp/delete/part/${data.part.id}/project-number/${data.projectNumber}/nc-id/${data.nonConformiteId}`).then(response => {
                commit('isLoading', false);
                resolve(response);
            }).catch(error => {
                commit('isLoading', false);
                reject(error);
            })
        })
    },

    //Update GNC task date in Syteline/Stageline system
    updateTaskDateInProject({state, commit}, data) {
        commit('isLoading', true);
        return new Promise( (resolve, reject) => {
            $axios.post(`/erp/update/task-date`, data).then(response => {
                commit('isLoading', false);
                resolve(response);
            }).catch(error => {
                commit('isLoading', false);
                reject(error);
            })
        })
    },

    fetchUpdateProjectDescriptionToSyteline({state, commit}, data) {
        commit('isLoading', true);
        return new Promise( (resolve, reject) => {
            let encode = JSON.stringify(data);
            $axios.post(`/erp/update-project-description/${encode}`).then(response => {
                commit('isLoading', false);
                resolve(response);
            }).catch(error => {
                commit('isLoading', false);
                reject(error);
            })
        })
    },

    fetchAddNewProjectNumberToNonConformite({state, commit}, data) {
        return new Promise( (resolve, reject) => {
            $axios.post(`/nc/${data.Id}/maintenance/syteline-project-number/${data.Projet}/`).then(response => {
                resolve(response);
            }).catch(error => {
                commit('isLoading', false);
                reject(error);
            })
        })
    },

    fetchEmployeesFromSyteline({state, commit}) {
        $axios.get('/erp/employees').then( response => {
            commit('setDataEmployeesEntrepriseStore', response.data)
        }).catch(function (error) {
        });
    },

    fetchSuppliersFromSyteline({state, commit}) {
        $axios.get(`/erp/suppliers`).then( response => {
            commit('setDataSuppliersEntrepriseStore', response.data)
        }).catch(function (error) {
        });
    },

    async fetchPartNoFromSyteline({commit}, searchObject) {
        if(searchObject.from === 'main'){
            commit('isSpinnerLoading', true);
        }
        else{
            commit('isSpinnerLoading2', true);
        }

        await $axios.get(`/erp/part-no/${searchObject.noAssemblage}`).then( response => {
            if(searchObject.from === 'main'){
                commit('setDataPartNos', response.data);
                commit('isSpinnerLoading', false);
            }
            else{
                commit('setDataPartNosAdd', response.data);
                commit('isSpinnerLoading2', false);
            }
        }).catch(function (error) {
        });
    },

    fetchCheckUpTypeFromSyteline({state}) {
    return new Promise( (resolve, reject) => {
        $axios.get(`/erp/checkUp/`).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        })
    })
},

    //SMART
    async validateSMARTProjectNumber({state}, projectNumber) {
        const validProject = await $axios.get(`/nc/validate-project/${projectNumber}`);
        return validProject.data.valid;
    },

    //fetches all stage units
    fetchStageUnits({state, commit}, data) {
        $axios.get(`/units/`).then( response => {
            commit('setDataStageUnitsEntrepriseStore', response.data)
        }).catch(function (error) {
        });
    },

    //fetches all stage models
    fetchStages({state, commit}, data) {
        $axios.get(`/stages/`).then( response => {
            commit('setDataStagesEntrepriseStore', response.data)
        }).catch(function (error) {
        });
    },

    fetchGroups({state, commit}) {
        return new Promise( (resolve, reject) => {
            $axios.get(`users/groups/${ENV.APPLICATION_ID}`).then( response => {
                commit('setGroupsEntrepriseStore', response.data);
                resolve(true);
            }).catch(function (error) {
                reject(error);
            });
        })
    },

    async fetchFutureProjects( { state, commit }){
        const projects = await $axios.get('/nc/future-projects');
        commit('setFutureProjects', projects.data);
    },

    fetchUsers({state, commit}) {
        return new Promise( (resolve, reject) => {
            $axios.get(`users/application/${ENV.APPLICATION_ID}`).then( response => {
                commit('setUsersEntrepriseStore', response.data);
                resolve(true);
            }).catch(function (error) {
                reject(error);
            });
        })
    },

    /*Liste de tous les utilisateurs qui ne sont pas dans GNC */
    async fetchNoneGncUsers( { commit } ){
        let users = await $axios.get(`users/listNoneGncUsers`);
        commit('setNoneGncUsersEntrepriseStore', users.data);
        return true;
    },

    addUserToGroup({ state, commit }, data) {
        return new Promise( (resolve, reject) => {
            $axios.post(`/users/${data.userId}/groups/${data.groupeId}`).then( response => {
                commit('replaceUser', response.data);
                resolve(response.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    },

    removeUserFromGroup({ state, commit }, data) {
        return new Promise( (resolve, reject) => {
            $axios.delete(`/users/${data.userId}/groups/${data.groupeId}`).then( response => {
                commit('replaceUser', response.data);
                resolve(response.data);
            }).catch(function (error) {
                reject(error);
            });
        })
    },

    async modifierProductCodeNonConformites({ state, commit }, { nonConformite, newUnitModel } ){
        commit('isLoading', true);
        return new Promise( (resolve, reject) => {
            $axios.post(`/erp/${nonConformite.id}/modifierProductCode`, { newUnitModel: newUnitModel }).then( response => {
                commit('isLoading', false);
                resolve(response);
            }).catch(function (error) {
                commit('isLoading', false);
                reject(error);
            });
        })
    },
};

const getters = {
    //gets sub departments by department id
    getDepartementsByParent: (state) => (parentId) => {
        return state.departements.filter(dep => dep.parentId === parentId);
    },
    //gets departments list
    getParentDepartements: (state) => {
        return state.departements.filter(dep => !dep.parentId);
    },
    //gets a department by id
    getDepartement: (state) => (departementId) => {
        return state.departements.find(departement => departement.id === departementId);
    },
    //gets sub departments list
    getListeSousDepartement: (state) => {
        let retval = state.departements.filter(departement => {
            return departement.parentId !== null;
        });
        return retval;
    },
    getStageUnitByID: (state) => (id) => {
        return state.units.find(su => su.id === id);
    },
    getGroups : (state) => {
        return state.groupes;
    },
    getUsers : (state) => {
        return state.users;
    },

    //gets stage models list
    getStages: state => {
        return state.stages;
    },

    //gets stage model name by id
    getStageModelName: (state) => (id) => {
        let retval = state.stages.find( stage => {
            return stage.id === id;
        });
        if (retval != null) {
            return retval.proposalModelName;
        }
        return '';

    },

    //gets stage units list
    getStageUnits: state => {
        return state.units;
    },

    //gets stage unit name by id
    getStageUnitName: (state) => (id) => {
        let retval = state.units.find( unit => {
            return unit.id === id;
        });
        if (retval != null) {
            return retval.unit;
        }
        return '';
    }
};

export default {
    state, actions, mutations, getters
};
