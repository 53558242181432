<template>
    <v-container fluid>
        <div>
            <div class="viewTitle pa-2">
                Tableau de bord
                <span class="hidden-sm-and-down">- {{ loginStore.loggedUser?.fullName }}</span>
            </div>
        </div>

        <v-layout ma-1>
            <v-layout align-end justify-end row fill-height>
                <!-- button to create NC -->
                <v-btn :to="'/nc/create' + (toggle_mode === 0 ? '' : toggle_mode === 1 ? '?mode=maintenance' : '?mode=sav')" small color="success" class="my-1 add-btn"><v-icon>add</v-icon>Créer</v-btn>
                <v-spacer/>

                <!-- button to toggle between list and card view -->
                <v-btn-toggle v-model="toggle_view" mandatory>
                    <!-- button card view -->
                    <!-- button is disabled for Maintenance and SAV view -->
                    <v-btn small :color="toggle_view === 0 ? '#90CAF9' : null " :disabled="toggle_mode === 1 || toggle_mode === 2">
                        <v-icon>dashboard</v-icon>
                    </v-btn>

                    <!-- button list view -->
                    <v-btn small :color="toggle_view === 1 ? '#90CAF9' : null">
                        <v-icon>list</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-layout>
        </v-layout>

        <v-layout align-end justify-end row fill-height>
            <!-- print button -->
            <v-btn
                flat
                color="green"
                @click="goToPrintNCListPage"
            >
                <v-icon class="mr-1">print</v-icon>Impression
            </v-btn>

            <!-- URL button -->
            <v-btn
                flat
                color="green"
                @click="showURL = true"
            >
                <v-icon class="mr-1">fas fa-link</v-icon>Lien partage
            </v-btn>

            <!-- refresh button -->
            <!-- NOTE: card view still has its own refresh function -->
            <v-btn
                flat
                color="green"
                @click="refresh"
                :loading="loading"
            >
                <v-icon class="mr-1">fas fa-sync-alt</v-icon>Actualiser
            </v-btn>
        </v-layout>

        <v-container fluid>
            <!-- advanced search -->
            <!-- MOTE: appears for list view only -->
            <div
                v-if="toggle_view === 1"
                class="shadow my-3"
            >
                <AdvancedSearch
                    :advancedSearchParams="advancedSearchParams"
                    :departments="getParentDepartements"
                    :stageModels="getStages"
                    :stageUnits="getStageUnits"
                    :states="advancedSearchParams.type === 'qualite' ? getEtatsQualite : advancedSearchParams.type === 'maintenance' ? getEtatsMaintenance : getEtatsSav"
                    :users="getUsers"
                    :graviteList="graviteList"
                    @searchWithAdvancedParams="searchWithAdvancedParams"
                    @saveAdvancedSearchParams="saveAdvancedSearchParams"
                    @refresh="refresh"
                />
            </div>

            <!-- view (list or card) -->
            <component
                :is="currentModeComponent"
                :ref="currentModeComponent"
                :listeNonConformites="getListeNonConformites"
                :isMaintenance="nonConformiteStore.mode  === 'maintenance'"
                @setPrintableList="setPrintableList"
                @searchPaginationPageLink="searchWithAdvancedParamsAndPageNumber($event)"
            />
        </v-container>

        <!-- DIALOGS -->
        <!-- show URL dialog -->
        <v-dialog
            v-model="showURL"
        >
            <v-card>
                <v-card-title>Partager</v-card-title>
                <v-card-text><p>{{fullURL}}</p></v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    //VUEX
    import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

    //controls
    import AdvancedSearch from "../components/controls/AdvancedSearch";

    import DashboardListe from '../components/DashboardListe';
    import DashboardCartes from '../components/DashboardCartes';

    export default {
        name: "Dashboards",
        components: {
            DashboardListe,
            DashboardCartes,

            //controls
            AdvancedSearch,
        },
        async created() {
            this.entrepriseStore.showLoading = true;

            await Promise.all([
                //this.fetchListeNonConformites(),
                this.fetchParamsNonConformite(),
                this.fetchDataDepartementsEntrepriseStore(),
                this.fetchStages(),
                this.fetchStageUnits(),
                this.fetchUsers(),
            ]);

            //ADVANCED SEARCH PARAMS IN URL OR LOCAL STORAGE
            for(let paramName of Object.keys(this.advancedSearchParams)) {
                //gets advanced search  values from URL
                if (this.$route.query.hasOwnProperty(`AS${paramName}`)) {
                    //if param is departmentId or subDepartmentId
                    if(paramName === 'departmentId' || paramName === 'subDepartmentId') {
                        this.advancedSearchParams[paramName] = parseInt(this.$route.query[`AS${paramName}`]);
                    }
                    //if param is states, stageModelIds, stageUnitIds membersIds, or ncNumbers
                    else if(paramName === 'states' || paramName === 'stageModelIds' || paramName === 'stageUnitIds' || paramName === 'memberIds' || paramName === 'ncNumbers'|| paramName === 'gravite') {
                        let value = this.$route.query[`AS${paramName}`];

                        if(!Array.isArray(value)) {
                            value = [this.$route.query[`AS${paramName}`]];
                        }

                        if(paramName === 'states') {
                            this.advancedSearchParams[paramName] = value;
                        }
                        else {
                            this.advancedSearchParams[paramName] = this.parseArrayItemsToInt(value);
                        }
                    }
                    //if param is requiredCorrectiveActionState
                    //NOTE: the value has to be an integer for the backend search and for the frontend radio button
                    else if(paramName === 'requiredCorrectiveActionState') {
                        this.advancedSearchParams[paramName] = parseInt(this.$route.query[`AS${paramName}`]);
                    }
                    //every other params
                    else {
                        this.advancedSearchParams[paramName] = this.$route.query[`AS${paramName}`];
                    }
                }
                //gets advanced search values from local storage
                else {
                    let paramValue = localStorage.getItem(`AS${paramName}`);

                    if (!!paramValue) {
                        //if param is departmentId, subDepartmentId or requiredCorrectiveActionState
                        if(paramName === 'departmentId' || paramName === 'subDepartmentId' || paramName === 'requiredCorrectiveActionState') {
                            this.advancedSearchParams[paramName] = parseInt(paramValue);
                        }
                        //if param is states, stageModelIds, membersIds, or ncNumbers
                        else if(paramName === 'states' || paramName === 'stageModelIds' || paramName === 'stageUnitIds' || paramName === 'memberIds' || paramName === 'ncNumbers' || paramName === 'ncNumbers') {
                            let stringArray = paramValue.split(',');

                            if(paramName === 'states') {
                                this.advancedSearchParams[paramName] = stringArray;
                            }
                            else {
                                this.advancedSearchParams[paramName] = this.parseArrayItemsToInt(stringArray);
                            }
                        }
                        //every other params
                        else {
                            this.advancedSearchParams[paramName] = paramValue;
                        }
                    }
                }
            }

            this.saveAdvancedSearchParams();


            //if there is at least another filled param other than type, we load the nc list with the advanced search params
            if(this.isAdvancedSearchInitialized) {
                await this.searchWithAdvancedParams();
            }
            else {
                let mode = localStorage.getItem('ncMode')
                if (mode == null) {
                    mode = 'qualite';
                }
                await this.setMode((mode));
            }

            this.entrepriseStore.showLoading = false;
        },
        computed: {
            //VUEX
            ...mapGetters([
                //entreprise store
                'getParentDepartements',
                'getStages',
                'getStageUnits',
                'getUsers',

                //non conformite store
                'getListeNonConformites',
                'getEtatsQualite',
                'getEtatsMaintenance',
                'getEtatsSav',
            ]),

            ...mapState({
                entrepriseStore: state => state.entrepriseStore,
                loginStore: state => state.loginStore,
                nonConformiteStore: state => state.nonConformiteStore
            }),
            currentModeComponent: function () {
                return this.currentMode;
            },
            isGNCSAV() {
                return this.$store.getters['isGNCSAV'];
            },
            isGNCMaintenance() {
                return this.$store.getters['isGNCMaintenance'];
            },
            isGNCQualite() {
                return (this.$store.getters['isGncUser'] || this.$store.getters['isGncSupervisor'] || this.$store.getters['isGncQualite'] || this.$store.getters['isGncAdmin'])
            },
            fullURL() {
                //return window.location.href;
                return 'https://gnc.groupestageline.com' + this.$route.fullPath;
            },

            //ADVANCED SEARCH
            //tells if advanced search is initialized or not (if a field other than type has been filled)
            isAdvancedSearchInitialized() {
                const advancedSearchParams = this.advancedSearchParams;

                return (!!advancedSearchParams.departmentId || !!advancedSearchParams.subDepartmentId || !!advancedSearchParams.projectNumber ||
                    !!advancedSearchParams.assemblyNumber || !!advancedSearchParams.keyWords || !!advancedSearchParams.withRequiredCorrectiveAction ||
                    (!!advancedSearchParams.creationDateFrom && !!advancedSearchParams.creationDateTo) ||
                    (!!advancedSearchParams.approuverTravailDateFrom && !!advancedSearchParams.approuverTravailDateTo) ||
                    (Array.isArray(advancedSearchParams.stageModelIds) && advancedSearchParams.stageModelIds.length > 0) ||
                    (Array.isArray(advancedSearchParams.stageUnitIds) && advancedSearchParams.stageUnitIds.length > 0) ||
                    (Array.isArray(advancedSearchParams.ncNumbers) && advancedSearchParams.ncNumbers.length > 0) ||
                    (Array.isArray(advancedSearchParams.states) && advancedSearchParams.states.length > 0) ||
                    (Array.isArray(advancedSearchParams.memberIds) && advancedSearchParams.memberIds.length > 0));
            },
        },
        data(){
            return {
                //PRINT
                //list to send to the print page
                /*NOTES:
                    - this list is passed as a prop to the DashboardListe.vue component
                    - every times a filter is applied to the list of the DashboardList.vue component, it will set the printableList with
                    the filtered list of the DashboardList.vue component
                * */
                printableList: [],

                //ADVANCED SEARCH
                //advanced search params
                advancedSearchParams: {
                    type: 'qualite',
                    departmentId: '',
                    subDepartmentId: '',
                    stageModelIds: [],
                    stageUnitIds: [],
                    states: [],
                    memberIds: [],
                    ncNumbers: [],
                    projectNumber: '',
                    assemblyNumber: '',
                    creationDateFrom: '',
                    creationDateTo: '',
                    approuverTravailDateFrom: '',
                    approuverTravailDateTo: '',
                    keyWords: '',
                    withRequiredCorrectiveAction: false,
                    requiredCorrectiveActionState: null,
                    gravite:[]
                },

                graviteList: [
                    {
                        "id": 2,
                        "label_fr": "Gravité 2"
                    },
                    {
                        "id": 3,
                        "label_fr": "Gravité 3"
                    }
                ],

                /*
                * Le model pour le v-btn-toggle est toggle_view, il contient la position du bouton qui est
                * présentément actif, par défaut, on active la vue dashboard, il est le premier dans le v-btn-toggle [0]
                * */
                currentMode: 'DashboardListe',
                modes: ['DashboardCartes', 'DashboardListe'],
                toggle_view: 1,
                toggle_mode: 0,
                loading: false,

                //the filtered headers, useful for generating a search URL that includes the filters
                filteredListHeaders: [],
                //show the url dialog
                showURL: false
            }
        },
        methods: {
            //VUEX
            ...mapActions([
                //non conformite store
                'fetchListeNonConformites',
                'fetchListeNonConformitesMaintenance',
                'fetchListeNonConformitesSAV',
                'fetchParamsNonConformite',
                'fetchAdvancedSearchResults',
                'fetchAdvancedSearchResultsByPageNumber',

                //entreprise store
                'fetchDataDepartementsEntrepriseStore',
                'fetchStages',
                'fetchStageUnits',
                'fetchUsers',
            ]),

            ...mapMutations(['setNCMode']),

            //sets printable list
            //NOTE: this list is always to the current list that is showed (if the list is filtered, we will get the filtered list)
            setPrintableList(filteredList) {
                this.printableList = filteredList;
            },

            //goes to print nc list page
            goToPrintNCListPage() {
                let printNcListPage = window.open('/print', '_blank');
                printNcListPage.ncList = this.printableList
            },

            //refreshes page base on card view or list view
            async refresh() {
                this.entrepriseStore.showLoading = true;
                this.$store.commit('resetPaginationOptions');

                //if user is in card mode (Nc of type Qualité only)
                if(this.currentMode === 'DashboardCartes') {
                    await this.refreshCardView();
                }
                //if an advanced search is initialized
                else if(this.isAdvancedSearchInitialized) {
                    await this.searchWithAdvancedParams();
                }
                else {
                    await this.refreshList();
                }
                this.entrepriseStore.showLoading = false;
            },

            //refresh method for Qualité card view only
            async refreshCardView(){
                this.loading = true;

                await this.fetchListeNonConformites();

                //Fonction refresh est implémenter dans DashboardCartes
                setTimeout( () => {
                    this.$refs[this.currentMode].refresh();
                    this.loading = false;
                },1500);
            },

            //refreshes NC list
            async refreshList() {
                this.entrepriseStore.showLoading = true;
                this.loading = true;

                //refrehses with NC Qualité list
                if (this.nonConformiteStore.mode === 'qualite') {
                    const success = await this.fetchListeNonConformites();

                    if(success) {
                        this.toggle_mode = 0;
                        this.loading = false;
                    }
                }
                //refrehses with NC Maintenance list
                else if(this.nonConformiteStore.mode  === 'maintenance') {
                    const success = await this.fetchListeNonConformitesMaintenance();

                    if(success) {
                        this.toggle_mode = 1;
                        this.loading = false;
                    }
                }
                //refrehses with NC SAV list
                else if(this.nonConformiteStore.mode  === 'sav') {
                    const success = await this.fetchListeNonConformitesSAV();

                    if(success) {
                        this.toggle_mode = 2;
                        this.loading = false;
                    }
                }
                this.entrepriseStore.showLoading = false;
            },


            //sets Qualité, Maintenance or SAV view
            async setMode(mode) {
                localStorage.setItem('ncMode', mode);

                //sets view as a list
                this.toggle_view = 1;

                //sets mode (qualite, maintenance or sav)
                this.setNCMode(mode);

                //refreshes NC list
                await this.refreshList();
            },

            //ADVANCED SEARCH
            //fetches advanced search results
            async searchWithAdvancedParams() {
                this.entrepriseStore.showLoading = true;
                this.loading = true;

                const advancedSearchParams = this.advancedSearchParams;

                //fetches advanced search results
                //const success = await this.fetchAdvancedSearchResults(advancedSearchParams);
                const success = await this.fetchAdvancedSearchResultsByPageNumber({advancedSearchParams:advancedSearchParams, pageNumber:1});

                if(success) {
                    //sets mode (qualite, maintenance or sav)
                    this.toggle_mode = advancedSearchParams.type === 'qualite' ? 0 : advancedSearchParams.type === 'maintenance' ? 1 : 2;
                }

                this.loading = false;
                this.entrepriseStore.showLoading = false;
            },

            //fetches advanced search results by page number | Méthode optimiser pour pagination |
            async searchWithAdvancedParamsAndPageNumber(pageNumber){
                this.entrepriseStore.showLoading = true;
                this.loading = true;

                const advancedSearchParams = this.advancedSearchParams;

                const success = await this.fetchAdvancedSearchResultsByPageNumber({advancedSearchParams:advancedSearchParams, pageNumber:pageNumber});

                if(success) {
                    //sets mode (qualite, maintenance or sav)
                    this.toggle_mode = advancedSearchParams.type === 'qualite' ? 0 : advancedSearchParams.type === 'maintenance' ? 1 : 2;
                }

                this.loading = false;
                this.entrepriseStore.showLoading = false;
            },

            //sets local storage and URL with advanced search params values
            saveAdvancedSearchParams() {
                let query = {};

                for(let paramName of Object.keys(this.advancedSearchParams)) {
                    //sets local storage
                    localStorage.setItem(`AS${paramName}`, this.advancedSearchParams[paramName] === '' || this.advancedSearchParams[paramName] === null || this.advancedSearchParams[paramName] === false ? '' : this.advancedSearchParams[paramName]);

                    //sets URL (we only sets the URL params if there is a value)
                    //NOTE: the corrective action completion radio group can return a value of 0
                    if(!!this.advancedSearchParams[paramName] || this.advancedSearchParams[paramName] === 0) {
                        query[`AS${paramName}`] = this.advancedSearchParams[paramName];
                    }
                }

                if (Object.keys(query).length > 0) {
                    //the router will add all query parameters correctly to the url.  the empty catch block is there because the router throws an exception in case of duplicate route
                    this.$router.push({ query }).catch(err => console.error(err));
                }
            },

            //UTILS
            //parses all values of an array to int
            parseArrayItemsToInt(array) {
                return array.map(item => parseInt(item));
            },

            //FILTERS (from DashboardList.vue)
            //sets URL filter params
            //the event that occurs when the table filters change
            listFiltersChange(headers) {
                //so what we do is to always keep the url up to date with the filters
                this.filteredListHeaders = headers;
                let query = {};
                //we generate a query object, and the router will do it's magic!
                this.filteredListHeaders.forEach(header => {
                    if (header.value != null && header.search != null && header.search !== '') {
                        query['filter' + header.value] = header.search;
                    }
                });
                if (Object.keys(query).length > 0) {
                    //the router will add all query parameters correctly to the url.  the empty catch block is there because the router throws an exception in case of duplicate route
                    this.$router.push({query: query}).catch(err => {console.error(err);});
                }
            },


        },
        watch: {
            /*
            * Quand le modele toggle_view change, on update le component qu'on doit afficher
            * */
            toggle_view: function(val, oldVal) {
                this.currentMode = this.modes[val];
            }
        },
    }
</script>

<style>
    .add-btn{
        position:relative;
        top: 10px;
    }

    .no-shadow {
        box-shadow:none !important;
    }
</style>
