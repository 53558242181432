<template>
    <div v-if="showAlert" class="overlay">
        <div class="custom-alert">
            <h2 class="title-content">
                {{ title }}
                <v-icon style="float:right;margin-right: 10px;color:#efca06;">outline_info</v-icon>
            </h2>
            <div class="alert-content">
                <p>{{ message }}</p>
            </div>
            <div class="alert-actions">
                <v-btn v-for="(param, index) in this.btnParams" :key="index" @click="buttonClick(param.name)">
                    <template v-if="index === 0" class="first-btn">
                        {{ param.label }}
                    </template>
                    <template v-else>
                        <div class="yes-cancel-btn">
                            {{ param.label }}
                        </div>
                    </template>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChoiceBoxMessage',
    data() {
        return {
            showAlert: false,
            message: '',
            title: '',
            btnParams:[],
        };
    },
    methods: {
        buttonClick(button) {
            this.showAlert = false;
            this.$emit('alert-response', button);
        },
        displayAlert(content, btnParams) {
            // Check if btnParams is not empty and every object has 'label' and 'name' properties
            const isValidParams = Array.isArray(btnParams) && btnParams.every(param =>
                Object.keys(param).includes('label')
                && Object.keys(param).includes('name')
                && param.label !== ''
                && param.name !== ''
            );

            if (isValidParams && content?.title?.length > 0 && content?.message?.length > 0) {
                this.title = content.title;
                this.message = content.message;
                this.btnParams = btnParams;
                this.showAlert = true;
            }
            else {
                if(content?.message?.length === 0) {
                    console.error('Empty title parameter provided!');
                }

                if(content?.message?.length === 0) {
                    console.error('Empty message parameter provided!');
                }

                if(!isValidParams) {
                    console.error('Invalid button parameters provided!');
                }
            }
        },
    },
};
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10%;
    overflow-y: hidden;
}
.custom-alert {
    z-index: 9999;
    width: 350px;
    border: 1px solid #343434;
    border-radius: 4px;
    box-shadow: 1px 1px 5px #232323;
    background-color: #252424;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title-content {
    padding-left: 10px;
    padding-top: 10px;
}
.alert-content {
    font-size: 16px;
    padding: 20px;
}
.alert-actions {
    padding-bottom: 10px;
    text-align: center;
}
.first-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px;
}
.yes-cancel-btn{
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    gap: 10px;
}
</style>
