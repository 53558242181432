import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import fr from 'vuetify/es5/locale/fr'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import updatedLocal from '@/updatedLocal';

Vue.use(Vuetify, {
    iconfont: 'md || fa',
    lang: {
        locales: { fr: {...fr, ...updatedLocal} },
        current: 'fr'
    },
    theme: {
        login: '#6c6c6d',
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
    }
});
