import $axios from '../../api';

const state = {
    listeNotifications: [],
    categories: [],
    causes: [],
    etats: [],
    etatsQualite: [],
    etatsMaintenance: [],
    etatsSav: [],
    list: [],
    listArchives: [],
    nonConformite: null,
    mode: 'qualite',
    paginationOptions:{}
};

const mutations = {
    setParamsNonConformite(state, params) {
        state.categories = params.data.categories;
        state.causes = params.data.causes;
        //contains all states (qualite, maintenance and SAV)
        state.etats = params.data.etats;
        //contains all Qualité states only
        state.etatsQualite = params.data.etatsQualite;
        //contains all Maintenance states only
        state.etatsMaintenance = params.data.etatsMaintenance;
        //contains all SAV states only
        state.etatsSav = params.data.etatsSav;
    },

    setListeNonConformites(state, ncList){
        state.list = ncList;
    },

    //this is added to put a NC# on non quality NCs
    setNullNumeroNCWithId(state) {
        state.list.forEach(nc => {
            if (nc.numeroNc == null) {
                nc.numeroNc = nc.id;
            }
        });
    },


    setListeNonConformitesArchives(state, payload){
        state.listArchives = payload.data.archives;
    },
    setListeNotifications(state, payload){
        state.listeNotifications = payload.data.notifications
    },

    setNotificationsReadStatus(state, payload){
        payload.data.notifications.map( item => {
            item.read_at = payload.readAt;
        });
    },

    setNCMode(state, mode) {
        state.mode = mode;
    },

    setPaginationOptions(state, options){
        state.paginationOptions = options;
    },

    resetPaginationOptions(state) {
        state.paginationOptions = {};
    },
};

const actions = {
    //fetches params lists (categories, causes and states)
    fetchParamsNonConformite({ state, commit }) {
        return new Promise( (resolve, reject) => {
            $axios.get('/nc/params/list').then( response => {
                commit('setParamsNonConformite', response);
                resolve(true);
            }).catch(function (error) {
                reject(error);
            });
        });
    },

    //fetches Qualité non conformites list
    fetchListeNonConformites( {state, commit} ) {
        return new Promise( (resolve, reject) => {
            $axios.get('/nc/').then( response => {
                commit('setListeNonConformites', response.data.ncList);
                resolve(true);
            }).catch(function (error) {
                reject(error);
            });
        }) ;
    },

    //fetches Maintenance non conformites list
    async fetchListeNonConformitesMaintenance({commit}) {
        try{
            const response = await $axios.get('nc/maintenance/');
            commit('setListeNonConformites', response.data.ncList);
            //these do not have a "numero nc", then we use the ID
            commit('setNullNumeroNCWithId');
            return true;
        }
        catch(err) {
            console.error(err);
        }
    },

    //fetches SAV non conformites list
    async fetchListeNonConformitesSAV({commit}) {
        try {
            const response = await $axios.get('nc/sav/');
            commit('setListeNonConformites', response.data.ncList);
            //these do not have a "numero nc", then we use the ID
            commit('setNullNumeroNCWithId');
            return true;
        }
        catch(err) {
            console.error(err);
        }
    },

    //fetches archives
    async fetchListeNonConformitesArchives( { state, commit }, recherche ) {
        try {
            const result = await $axios.get('/nc/archives/', {params: {'recherche': recherche}});
            commit('setListeNonConformitesArchives', result);
            return true;
        }
        catch(err) {
            console.error(err);
        }
    },

    //fetches NC by id
    async fetchNonConformiteById( { state, commit }, id ) {
        try {
            const result = await $axios.get(`/nc/${id}`);
            return result.data;
        }
        catch(err) {
            console.error(err);
        }
    },

    async resetListeNonConformitesArchives({ state }) {
        state.listArchives = [];
        return true;
    },

    /*
        On GET la liste des notifications pour l'utilisateur logger,
        Pas d'ID envoyer dans la route car on passe le TOKEN dans la requete
    */
    async fetchListeNotifications( { state, commit } ) {
        const result = await $axios.get('/nc/notifications/');
        commit('setListeNotifications', result);
        return true;
    },

    async ajouterNonConformites({ state, commit, rootState }, data) {
        try {
            let url = `/nc` + (data.id === null  ?  '' : '/' + data.id);

            const response = await $axios({
                method: data.id === null  ? 'post' : 'put',
                url: url,
                data: data
            });

            return response.data
        }
        catch(err) {
            console.error(err);
        }
    },

    //deletes image
    async supprimerImageNonConformite({state, commit}, data) {
        const response = await $axios.delete(`/nc/${data.nonConformite.id}/deleteImage/${data.image.origin}/${data.image.title}`);
        return response.data;
    },

    async ajouterImageNonConformites({ state, commit }, data ){
        if (data.nbImages > 0) {
            let url = `/nc/${data.nonConformite.id}/uploadImages`;
            const nc =  await $axios({
                method: 'post',
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 15000,
                data: data.images
            });
            return nc.data;
        }
        return {nonConformite: data.nonConformite};
    },

    //deletes files
    async supprimerFichierNonConformite({state, commit}, { nonConformite, file, fileOrigin }) {
        try {
            const { data } = await $axios.delete(`/nc/${nonConformite.id}/deleteFichier/${fileOrigin}/${file.name}`);
            return data.nc;
        }
        catch(err) {
            console.log(err);
        }
    },

    async ajouterFichiersNonConformite({ state, commit }, data){
        if (data.nbFichiers > 0) {
            let url = `/nc/${data.nonConformite.id}/uploadFichiers`;
            const nc =  await $axios({
                method: 'post',
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 15000,
                data: data.fichiers
            });
            return nc.data;
        }
        return {nonConformite: data.nonConformite};
    },

    async ajouterNoteNonConformites({ state, commit }, { nonConformite, note } ){
        try {
            //returned data contains all NC notes and NC id
            const { data } = await $axios.post(`/nc/${nonConformite.id}/ajouterNote`, { note: note });

            return data.notes
        }
        catch(err) {
            console.log(err);
        }
    },

    applyTransition({ state, commit, rootState }, data) {
        return new Promise( (resolve, reject) => {
            $axios.put(`/nc/${data.nc.id}/${data.transition}`).then(response => {
                //commit('applyTransition', {data : response.data, nc :data.nc});
                resolve({resolved: true, history: response.data.history});
            }).catch(error => {
                reject(error.response.data.errors.message);
            });
        })
    },

    setNotificationsReadStatus({ commit }, data){
        return new Promise( (resolve, reject) => {
            return $axios.put(`/nc/notifications/updateStatus`, data).then( response => {
                let payload = {
                    data: data,
                    readAt: response.data.readAt
                };
                commit('setNotificationsReadStatus', payload);
                resolve(true);
            }).catch(function (error) {
                reject(error);
            });
        })
    },

    abonnerUserNonConformite( { commit }, data){
        return new Promise( (resolve, reject) => {
            $axios.post(`/nc/${data.nonConformiteId}/abonnement/${data.userId}`, data).then(response => {
                resolve(response);
            })
            .catch( error => {
                reject(error);
            });
        });
    },

    async desabonnerUserNonConformite({ commit }, subscription) {
        try{
            const { data } = await $axios.put(`/nc/${subscription.nonConformiteId}/desabonnement/${subscription.userId}`, subscription);

            return data.nonConformite;
        }
        catch(err) {
            console.log(err);
        }
    },

    //fetches advanced search results
    async fetchAdvancedSearchResults({ dispatch }, advancedSearchParams) {
        try{
            const { data } = await $axios.post('/nc/search/advanced?page=1', advancedSearchParams);
            await dispatch('setListeNonConformitesAndPaginationOptions', data);

            return true
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    async fetchAdvancedSearchResultsByPageNumber({ dispatch }, {advancedSearchParams, pageNumber}){
        try{
            const { data } = await $axios.post('/nc/search/advanced?page='+pageNumber, advancedSearchParams);
            await dispatch('setListeNonConformitesAndPaginationOptions', data);
            return true
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    //Méthode interne de travail
    async setListeNonConformitesAndPaginationOptions({ commit }, data){
        commit('setListeNonConformites', data.ncList.data);
        let optionObject = {
            current_page : data.ncList.current_page,
            first_page_url:data.ncList.first_page_url,
            from:data.ncList.from,
            last_page:data.ncList.last_page,
            last_page_url:data.ncList.last_page_url,
            next_page_url:data.ncList.next_page_url,
            path:data.ncList.path,
            per_page:data.ncList.per_page,
            prev_page_url: data.ncList.prev_page_url,
            to: data.ncList.to,
            total: data.ncList.total,
            links: data.ncList.links,
        }
        commit('setPaginationOptions',optionObject);
    }
};

const getters = {
    //gets all causes
    getCauses: (state) => {
        return state.causes;
    },

    //gets all categories
    //NOTE: As long as the fetchParamsNonConformite() action as not been refactored, we have to interact with the categories from here
    getCategories: (state) => {
        return state.categories;
    },

    getListeNonConformites: (state) => {
        return state.list
    },

    getListeNonConformitesArchives: (state) => {
        return state.listArchives;
    },

    getListeNonConformitesByEtat: (state) => (etat) => {
        return state.list.filter( nc => nc.etat === etat);
    },

    getNonConformiteByID: (state) => (id) => {
        return new Promise( (resolve, reject) => {
            let nc = state.list.find( nc => {
                return nc.id === parseInt(id);
            });
            resolve(nc);
        })
    },

    //gets all states (Qualite, Maintenance, SAV)
    getNonConformiteEtats: (state) => {
        return state.etats;
    },

    //gets all states
    getStates: (state) => {
        return state.etats;
    },

    //gets Qualite states
    getEtatsQualite: state => {
        return state.etatsQualite;
    },

    //gets Maintenance states
    getEtatsMaintenance: state => {
        return state.etatsMaintenance;
    },

    //gets SAV states
    getEtatsSav: state => {
        return state.etatsSav;
    },

    getEtatLabel: (state) => (etat) => {
        let retval = state.etats.find( e => {
            return e.name === etat;
        });
        return retval.label_fr;
    },

    getListeNotifications: (state) => {
        return state.notifications;
    },
};

export default {
  state, actions, mutations, getters
}
