<template>
    <div>
        <v-layout row wrap>
            <!-- NC OF number -->
            <v-flex xs12 md2 pr-2>
                <v-text-field
                    v-model="nonConformite.of"
                    label="OF"
                />
            </v-flex>
            <!-- NC stage unit -->
            <v-flex xs12 md2 px-1>
                <v-autocomplete
                    id="nc-unit"
                    v-model="nonConformite.stageUnitId"
                    label="Unité"
                    :items="stageUnits"
                    item-value="id"
                    item-text="unit"
                    @change="getStageModel"
                    :disabled="projectIncludesUnit || (!projectIncludesUnit && !!nonConformite.stageId && !nonConformite.stageUnitId) || nonConformite.projet.length > 0"
                    :readonly="readOnly"
                    clearable
                />
            </v-flex>
            <!-- NC stage model -->
            <v-flex xs12 md3 px-1>
                <v-autocomplete
                    id="nc-stage"
                    v-model="nonConformite.stageId"
                    label="Modèle"
                    :items="stageModels"
                    item-value="id"
                    item-text="proposalModelName"
                    :disabled="!!nonConformite.stageUnitId || nonConformite.projet.length > 0"
                    :readonly="readOnly"
                    clearable
                />
            </v-flex>

            <!-- NC no assemblage -->
            <v-flex xs8 md3 px-1>
                <div style="position: relative;">
                    <v-autocomplete
                        id="nc-prt"
                        v-model="nonConformite.noAssemblage"
                        label="No de pièce ou d'assemblage"
                        :items="partNosAutocompleteItems"
                        item-value="Item"
                        item-text="Description"
                        :filter="filterParts"
                        :loading="isLoading"
                        :rules="[
                            v => !!v || 'Un numéro de pièce ou d\'assemblage est requis',
                        ]"
                        required
                        :readonly="readOnly"
                        @change="emitChangeNoAssemblage($event)"
                        @keyup="performSearch(true)"
                        clearable
                        :messages="projectNoMessages"
                    >
                        <template v-slot:item="prop">
                            <v-list-tile-content>
                                <v-list-tile-title>{{prop.item.Item}}</v-list-tile-title>
                                <v-list-tile-sub-title>{{prop.item.Description}}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </template>
                    </v-autocomplete>
                    <v-progress-circular
                        v-if="isLoading"
                        class="spinner"
                        indeterminate small
                    ></v-progress-circular>
                </div>
            </v-flex>

            <v-flex xs4 md2 px-1>
                <v-switch
                    label="Selon projet"
                    v-model="showProjectPartNos"
                    :disabled="!hasProjectPartNos"
                />
            </v-flex>
        </v-layout>

        <!-- STATES AND CRITICALITY (USED FOR NC OF TYPE SAV AND MAINTENANCE) -->
        <v-layout v-if="showStatesAndCriticalitySelectBox">
            <!-- states -->
            <v-flex xs6 class="mr-4">
                <v-select
                    v-model="nonConformite.etat"
                    :items="statesList"
                    item-text="text"
                    item-value="value"
                    @change="emitApplyTransition"
                    label="État"
                />
            </v-flex>

            <!-- criticality -->
            <v-flex xs6>
                <v-select
                    v-model="nonConformite.gravite"
                    :items="criticalityList"
                    label="Gravité"
                />
            </v-flex>
        </v-layout>

        <!-- NC title -->
        <v-layout row wrap>
            <v-flex xs12>
                <v-text-field
                    id="nc-title"
                    v-model="nonConformite.titre"
                    :label="titleLabel"
                    :rules="[
                    v => !!v || 'Un titre est requis',
                    v => v.length >= 5 || 'Minimum 5 caractères requis',
                    v => v.length <= 100 || 'Un titre ne peu contenir plus de 100 caractères'
                ]"
                    required
                    :readonly="readOnly"
                />
            </v-flex>
        </v-layout>

        <!-- NC description -->
        <v-layout row wrap>
            <v-flex xs12>
                <v-textarea
                    id="nc-description"
                    v-model="nonConformite.description"
                    :label="descriptionLabel"
                    :rules="[v => v.length <= 250 || 'Maximum 250 caractères permis']"
                    :readonly="readOnly"
                />
            </v-flex>
        </v-layout>

        <!-- NOTE: visible for supervisors and admins only -->
        <v-layout row wrap>
            <v-flex xs12 md5>
                <h2 class="mb-3">Département et cause</h2>

                <!-- NC category -->
                <!-- NOTE: Categories have been renamed to Détection Des Non-Conformités but are still called categories in the code(we only changed the labels) -->
                <v-flex xs12 mx-2 v-if="showNcParams && (isGNCAdmin || isGNCSupervisor)">
                    <v-select
                        id="nc-categorie"
                        :items="categories"
                        item-text="nom"
                        item-value="id"
                        label="Détection de la non-conformité"
                        v-model="nonConformite.ncCategorieId"
                        required
                        :rules="[v => !!v || 'La catégorie est requise']"
                        :readonly="readOnly"
                    >
                    </v-select>
                </v-flex>

                <!-- NC department -->
                <v-flex xs12 mx-2 v-if="showNcParams && (isGNCAdmin || isGNCSupervisor)">
                    <v-select
                        id="nc-parent-dept"
                        :items="departments?.filter(d => d.parentId === null)"
                        item-text="nom"
                        item-value="id"
                        label="Département en cause :"
                        v-model="nonConformite.ncParentDepartementId"
                        required
                        :rules="[v => !!v || 'Le département est requis']"
                        :readonly="readOnly"
                        @input="getSubDepartments"
                    />
                </v-flex>

                <!-- NC sub department -->
                <v-flex xs12 mx-2 v-if="selectedDepartmentHasSubDepartments && showNcParams && (isGNCAdmin || isGNCSupervisor)">
                    <v-select
                        id="nc-sous-dept"
                        :items="filteredSubDepartments"
                        item-text="nom"
                        item-value="id"
                        label="Sous-Département en cause :"
                        v-model="nonConformite.ncDepartementId"
                        :rules="[ v => !!this.nonConformite.ncDepartementId || 'Le sous département est requis']"
                        @change="subDepartmentChanged"
                        :readonly="readOnly"
                    />
                </v-flex>

                <!-- NC sub department provider -->
                <v-flex xs12 mx-2 v-if="nonConformiteHasProviderSubDepartment && showNcParams && (isGNCAdmin || isGNCSupervisor)">
                    <v-autocomplete
                        id="nc-cause-fournisseur"
                        v-model="nonConformite.causeFournisseurId"
                        label="Fournisseur en cause"
                        :items="providers"
                        item-value="VendNum"
                        item-text="Name"
                        clearable
                        :readonly="readOnly"
                    />
                </v-flex>

                <!-- NC cause -->
                <v-flex xs12 mx-2 v-if="showNcParams && (isGNCAdmin || isGNCSupervisor)">
                    <v-select
                        id="nc-cause"
                        :items="causes"
                        item-text="nom"
                        item-value="id"
                        label="Cause"
                        v-model="nonConformite.ncCauseId"
                        required
                        :rules="[v => !!v || 'La cause est requise']"
                        @change="causeChanged"
                        :readonly="readOnly"
                    />
                </v-flex>

                <v-flex class="ml-2" xs12>
                    <p class="error--text font-weight-bold mb-0">Cocher cette case pour marquer la NC comme urgente.  Un message sera envoyé à l'ingénierie.</p>
                    <div style="height: 40px;">
                        <v-checkbox
                            class="mt-0"
                            label="Marquer la non-conformité comme urgente"
                            v-model="nonConformite.urgent"
                        ></v-checkbox>
                    </div>
                </v-flex>

                <!-- SOLUTION REQUIRED (qualite only) -->
                <div class="ml-2" v-if="showSolutionRequired">
                    <!-- required switch button -->
                    <v-flex xs12>
                        <v-switch
                            class="mt-0"
                            v-model="nonConformite.solutionRequise"
                            label="Solution requise"
                            :disabled="isDisabled"
                        />
                    </v-flex>

                    <!-- proposed solution -->
                    <v-flex xs12>
                        <v-textarea
                            v-model="nonConformite.solutionProposee"
                            label="Solution proposée"
                            v-if="nonConformite.solutionRequise"
                            :readonly="readOnly"
                        />
                    </v-flex>

                    <!-- alternate solution -->
                    <v-flex xs12>
                        <v-textarea
                            v-model="nonConformite.solutionAlternative"
                            label="Solution alternative proposée par la qualité"
                            v-if="nonConformite.solutionRequise && !!nonConformite.solutionAlternative && !isGNCQualite"
                            readonly
                        />
                    </v-flex>
                </div>

                <!-- NC Need labor -->
                <v-flex class="ml-2" xs12>
                    <p class="error--text font-weight-bold mb-0">Cocher cette case créera une ressource main d'oeuvre dans Syteline à la création.</p>
                    <div style="height: 40px;">
                        <v-checkbox
                            class="mt-0"
                            label="Marquer la non-conformité comme retravaille à faire"
                            v-model="nonConformite.isNcNeedLabor"
                            @change="ncNeedLaborOnChange"
                        ></v-checkbox>
                    </div>
                </v-flex>
            </v-flex >

            <v-flex xs12 md6 class="center-div">
                <h2>Ajout d'utilisateur en suivi </h2>
                <NCAbonnements
                    :non-conformite="nonConformite"
                    :items="nonConformite.abonnes"
                    :readonly="nonConformiteEstTerminee"
                    :users="entrepriseStore.users"
                    @change="nonConformite.abonnes = $event"
                />

                <h2>Responsables de la résolution </h2>
                <v-autocomplete
                    id="rs-resolution"
                    v-model="resolutionNouvelUtilisateur"
                    label="Choix de l'utilisateur"
                    :items="entrepriseStore.users"
                    item-value="id"
                    item-text="fullName"
                    :rules="[ v => !!this.resolutionNouvelUtilisateur || 'Le responsables de la résolution est requis']"
                    @change="addResolutionMember($event)"
                    clearable
                    :readonly="selectionMade"
                />
                <v-autocomplete
                    v-model="resolutionNouveauDepartement"
                    label="Choix du département"
                    :items="departments?.filter(d => d.parentId === null)"
                    item-value="id"
                    item-text="nom"
                    @change="addResolutionDept($event)"
                    clearable
                />
                <div class="text-xs-center">
                    <v-chip
                        v-for="(resolutionMember, index) in this.resolutionMemberAndDeptList"
                        :color="resolutionMember.color"
                        close
                        text-color="white"
                        @input="suppResolutionMember(resolutionMember)"
                        :key="index"
                    >
                        {{resolutionMember.nom}}
                    </v-chip>
                </div>

                <!-- NC pièces supplémentaires -->
                <v-layout row nowrap v-if="nonConformite.projet.length > 0 && (isGNCAdmin || isGNCSupervisor)" class="mt-3">
                    <v-flex xs12>
                        <h2>Pièces supplémentaires</h2>
                        <div style="display: inline-flex; width: 100%;">
                            <v-icon @click="addingPartToProject" class="icon-round-btn" style="margin-top: 12px; margin-right: 15px; margin-left: 10px;">add</v-icon>

                            <div style="position: relative; width: 35%;">
                                <v-autocomplete
                                    id="nc-prt-add"
                                    v-model="partToAdd"
                                    label="No de pièce ou d'assemblage"
                                    :items="partNosAddAutocomplete"
                                    item-value="Item"
                                    item-text="Description"
                                    :filter="filterParts"
                                    :loading="isLoading"
                                    style="margin-right: 10px;"
                                    @keyup="performSearch(false)"
                                    clearable
                                    :messages="projectNoMessages"
                                >
                                    <template v-slot:item="prop">
                                        <v-list-tile-content>
                                            <v-list-tile-title>{{prop.item.Item}}</v-list-tile-title>
                                            <v-list-tile-sub-title>{{prop.item.Description}}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </template>
                                </v-autocomplete>
                                <v-progress-circular
                                    v-if="isLoading2"
                                    class="spinner"
                                    indeterminate small
                                ></v-progress-circular>
                            </div>

                            <v-text-field
                                label="Nombre de pièce"
                                v-model="partNumber"
                                type="number"
                                style="width:10%; margin-right: 10px;"
                            ></v-text-field>

                            <div style="width: 55%;">
                                <v-menu
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="20"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateTask"
                                            label="Date requis"
                                            style="width:160px; margin-right: 10px; float:right;"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTask" no-title @input="dateMenu = false"></v-date-picker>
                                </v-menu>

                                <v-btn style="margin-top:12px; margin-right: 10px; float:right;" @click="changeTaskDate">Changer date requis</v-btn>
                            </div>
                        </div>

                        <div class="parts-box">
                            <!-- newPart (HEADERS) -->
                            <div class="parts-box-header">
                                <div class="pl-2 pt-1 pb-1" style="width:50%; border-right: 2px solid black;">No de pièce ou d'assemblage</div>
                                <div class="pl-2 pt-1 pb-1" style="width:50%; border-right: 2px solid black;">Nombre de pièce</div>
                            </div>
                            <!-- newPart (ROWS) -->
                            <div v-for="(part, index) in newPart" class="parts-box-row" style="font-size: 18px;">
                                <div class="pl-2 pt-1 pb-1" style="width:50%;">{{part.id}}</div>
                                <div class="pl-2 pt-1 pb-1" style="width:50%;">x{{part.number}}<v-icon style="color:red; float:right; margin-right: 8px;" @click="deletePart(index)">delete</v-icon></div>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    //controls
    import ImagesUpload from "./ImagesUpload";
    import FilesUpload from "./FilesUpload";
    import TagsTable from "./TagsTable";
    import NCAbonnements from "./NC-Abonnements";
    import projectMixin from "../../mixins/projectMixin";
    import {mapState} from "vuex";
    import $axios from "@/api";

    export default {
        name: "DemandDetails",
        mixins: [projectMixin],
        props: [
            'nonConformite',
            'titleLabel',
            'descriptionLabel',
            'readOnly',
            'isDisabled',
            'showNcParams',
            'showSolutionRequired',
            'showStatesAndCriticalitySelectBox',
            'criticalityList',
            'statesList',
            'isGNCQualite',
            'isGNCAdmin',
            'isGNCSupervisor',
            'projectIncludesUnit',
            'projectMessages',
            'projectErrorMessages',
            'stageUnits',
            'stageModels',
            'partNos',
            'partNosAdd',
            'projectPartNos',
            'categories',
            'departments',
            'causes',
            'employees',
            'providers',
            'newSytelineProjectNumber',
            'isSpinnerLoading',
            'isSpinnerLoading2'
        ],
        components: {
            //controls
            ImagesUpload,
            FilesUpload,
            TagsTable,
            NCAbonnements
        },
        computed: {
            ...mapState({
                //nonConformiteStore: state => state.nonConformiteStore,
                //loginStore: state => state.loginStore,
                entrepriseStore: state => state.entrepriseStore,
            }),

            membersList: function () {
                return this.nonConformite.resolutionMembersList;
            },

            nonConformiteEstTerminee(){
                return (this.nonConformite.etat === 'demande_rejetee' || this.nonConformite.etat === 'travail_approuve') && !this.isGNCAdmin;
            },

            //checks if an employee needs to be linked to the selected cause
            nonConformiteHasEmployeeCause() {
                let employeeCause = this.getEmployeeCause();

                return !!this.nonConformite.ncCauseId && !!employeeCause && employeeCause.id === this.nonConformite.ncCauseId;
            },

            //checks if a provider needs to be linked to the selected sub department
            nonConformiteHasProviderSubDepartment(){
                let providerSubDepartment = this.getProviderSubDepartment();

                return !!this.nonConformite.ncDepartementId && !!providerSubDepartment && providerSubDepartment.id === this.nonConformite.ncDepartementId;
            },

            //checks if selected department has sub departments
            selectedDepartmentHasSubDepartments(){
                return (!!this.filteredSubDepartments && this.filteredSubDepartments.length > 0) || !!this.nonConformite.ncDepartementId;
            },

            //what is in the noAssemblage auto-complete
            partNosAutocompleteItems() {
                if (this.showProjectPartNos && this.hasProjectPartNos) {
                    return this.projectPartNos;
                }
                else {
                    return this.partNos;
                }
            },

            partNosAddAutocomplete(){
                return this.partNosAdd;
            },

            hasProjectPartNos() {
                return Array.isArray(this.projectPartNos) && this.projectPartNos.length > 0;
            },

            projectNoMessages() {
                let message = '';
                if (this.nonConformite.noAssemblage != null && this.nonConformite.noAssemblage !== '') {
                    let prt = this.partNos.find(prt => prt.prt_no === this.nonConformite.noAssemblage);
                    if (prt != null) {
                        message = prt.prt_desc + ' ';
                    }
                    //add to the message if the part is included or not in the project list
                    if (this.hasProjectPartNos) {
                        let foundPart = this.projectPartNos.find(prt => prt.prt_no === this.nonConformite.noAssemblage);
                        if (foundPart == null) {
                            message += "(hors projet)";
                        }
                    }
                }

                return [message.trim()];
            }
        },
        data: function() {
            return {
                selectionMade: false,
                isLoading: false,
                isLoading2: false,

                //sub departments
                filteredSubDepartments: [],
                showProjectPartNos: false,

                //Resolution
                resolutionNouvelUtilisateur: null,
                resolutionNouveauDepartement:null,
                resolutionMember:null,
                resolutionDept:null,
                resolutionMemberAndDeptList:[],

                partNumber: 1,
                dateMenu: false,
                dateTask: null,
                partToAdd:'',
                newPart:[],
            }
        },
        mounted() {
            //fetches sub departments list if NC has a sub department id
            //NOTE: if there is a sub department id, we have to fetch the list to be able to fill the select input
            this.nonConformite.ncDepartementId && this.getSubDepartments();

            if(this.nonConformite.noAssemblage && this.nonConformite.noAssemblage?.length > 0){
                this.$emit('searchNoAssemblage', {
                    noAssemblage:this.nonConformite.noAssemblage,
                    from:'main'
                });
            }

            if(this.nonConformite.resolutionMembersList != null && this.membersList != null) {
                let membersListArray = JSON.parse(this.membersList);
                membersListArray.forEach((member) => {
                    this.resolutionMemberAndDeptList.push(member);
                });
            }

            this.resolutionMemberAndDeptList.forEach((member) => {
                if(member.type === 'member'){
                    this.selectionMade = true;
                    this.resolutionNouvelUtilisateur = member.id;
                }
            });

            if(this.nonConformite.parts && this.nonConformite.parts?.length > 0){
                this.nonConformite.parts.forEach((part)=>{
                    this.newPart.push({
                        id: part.partId,
                        number: part.partNb
                    });
                });
            }

            if(this.nonConformite.dateTask){
                this.dateTask = this.nonConformite.dateTask;
            }
        },
        methods: {
            suppResolutionMember(resolution){
                //Want to delete resolution in resolutionMemberAndDeptList array that is resolution object in it. resolution.nom is the param to compare.
                this.resolutionMemberAndDeptList = this.resolutionMemberAndDeptList.filter(item => item.nom !== resolution.nom);
                this.selectionMade = false;
                this.resolutionNouvelUtilisateur = null;
            },

            addResolutionDept(event){
                if(this.resolutionNouveauDepartement != null){
                    let dept = this.departments?.filter(d => d.parentId === null).find( dept => dept.id === event);
                    this.resolutionDept = {
                        id: dept.id,
                        nom : dept.nom,
                        type : 'dept',
                        color: '#af4c4c'
                    };
                    this.resolutionMemberAndDeptList.push(this.resolutionDept);
                    this.nonConformite.resolutionMembersList = JSON.stringify(this.resolutionMemberAndDeptList);
                    this.$emit('resolutionMembersList', this.nonConformite.resolutionMembersList);
                }
            },

            addResolutionMember(event){
                this.selectionMade = true;
                if(this.resolutionNouvelUtilisateur != null){
                    let user = this.entrepriseStore.users.find( user => user.id === event);
                    this.resolutionMember = user.fullName;
                    this.resolutionMember = {
                        id : user.id,
                        nom : user.fullName,
                        type : 'member',
                        color: 'primary'
                    };
                    this.resolutionMemberAndDeptList.push(this.resolutionMember);
                    this.nonConformite.resolutionMembersList = JSON.stringify(this.resolutionMemberAndDeptList);
                    this.$emit('resolutionMembersList', this.nonConformite.resolutionMembersList);
                }
            },

            filterParts(item, queryText) {
                const searchText = new RegExp(queryText.toLowerCase().replace('%', '(.*)'));
                const searchPrtNo = item.Item.toLowerCase();
                const searchDescription = item.Description.toLowerCase();

                return searchPrtNo.match(searchText) || searchDescription.match(searchText);
            },

            //emits apply transition
            emitApplyTransition(transition) {
                this.$emit('applyTransition', transition, 'DETAILS');
            },

            //gets sub departments of selected department
            getSubDepartments() {
                //gets sub departments of the selected department id
                let subDepartments = this.departments?.filter(d => d.parentId === this.nonConformite.ncParentDepartementId);

                //if the selected sub departments id found in the sub departments list
                //NOTE: if the value is NULL or empty, nothing will be found
                if(!subDepartments.find(s => s.id === this.nonConformite.ncDepartementId)) {
                    //resets sub departments id
                    this.nonConformite.ncDepartementId = null;

                    //resets provider sub department id
                    //NOTE: causeFournisseurId is a non_conformites table column that will need to be refactored to providerSubDepartmentId
                    this.nonConformite.causeFournisseurId = null;
                }

                this.filteredSubDepartments = subDepartments
            },

            //gets stage model of selected stage unit
            getStageModel() {
                //gets stage unit with the selected stage unit id
                const selectedStageUnit = this.stageUnits.find(su => su.id === this.nonConformite.stageUnitId);

                //if a stage unit is found
                if (!!selectedStageUnit) {
                    //gets stage model id of selected stage unit
                    this.nonConformite.stageId = selectedStageUnit.stageId;
                }
                else {
                    //sets the stage model id to NULL
                    this.nonConformite.stageId = null;
                }

                //parce que les changements affectent les autocompletes unit et stage mais ces derniers ne se reloadent pas toujours comme il faut
                this.$forceUpdate();
            },

            //gets sub department that needs to show providers list
            getProviderSubDepartment() {
                return this.departments?.find(d => d.id === 27);
            },

            //gets cause that needs to show employees list
            getEmployeeCause() {
                return this.causes.find(c => c.id === 11);
            },

            //sets provider sub department id (stiil named cause fournisseur id for now) depending on the selected sub department
            subDepartmentChanged() {
                let providerSubDepartment = this.getProviderSubDepartment();

                if(this.nonConformite.ncDepartementId !== providerSubDepartment.id) {
                    //NOTE: causeFournisseurId is a non_conformites table column that will need to be refactored to providerSubDepartmentId
                    this.nonConformite.causeFournisseurId = null;
                }
            },

            //sets cause employee id depending on the selected cause
            causeChanged() {
                let employeeCause = this.getEmployeeCause();

                if(this.nonConformite.ncCauseId !== employeeCause.id) {
                    this.nonConformite.causeEmployeId = null;
                }
            },

            //Recherche no assemblage
            performSearch(main) {
                let searchObject = {};
                if(main){
                    let noAssemblage = document.getElementById('nc-prt').value;
                    if(noAssemblage && noAssemblage.length === 3){
                        searchObject.noAssemblage = noAssemblage;
                        searchObject.from  = 'main';
                        this.$emit('searchNoAssemblage', searchObject);
                    }
                }
                else{
                    let noAssemblage = document.getElementById('nc-prt-add').value;
                    if(noAssemblage && noAssemblage.length === 3){
                        searchObject.noAssemblage = noAssemblage;
                        searchObject.from  = 'add';
                        this.$emit('searchNoAssemblage', searchObject);
                    }
                }

            },

            async addingPartToProject(){
                if(this.partToAdd.length > 0 && this.partNumber > 0){
                    let partExists = this.newPart.find(part => part.id === this.partToAdd);
                    let action = null;
                    let part = null;

                    if(partExists){
                        //Update existing part to project resources
                        partExists.number = Number(this.partNumber) + Number(partExists.number);
                        part = partExists;
                        action = "update";
                    }
                    else {
                        //Add new part to project resources
                        part = {
                            id: this.partToAdd,
                            number: this.partNumber,
                        };
                        this.newPart.push(part);
                        action = "add";
                    }

                    let payload = {
                        nonConformiteId:this.nonConformite.id,
                        projectNumber:this.nonConformite.projet,
                        action:action,
                        part:part
                    };

                    //Add Or Update in Syteline
                    this.$store.dispatch('addUpdatePartInProject', payload).then(response => {
                        this.$emit('updateGncMessage', {
                            type:'success',
                            message:response.data.message
                        });
                    }).catch( error => {
                        this.$emit('updateGncMessage', {
                            type:'error',
                            message:error
                        });
                    });
                }
                else{
                    alert("Vous devez choisir une pièce, le nombre de pièce et la date d'échéance!");
                }
            },

            async deletePart(index) {
                let partToDelete = this.newPart[index];

                let confirmation = window.confirm('Voulez-vous vraiment supprimer la pièce ' + partToDelete.id +' ?');
                if (confirmation) {
                    let payload = {
                        nonConformiteId:this.nonConformite.id,
                        projectNumber:this.nonConformite.projet,
                        part:partToDelete
                    };

                    //Delete in Syteline
                    this.$store.dispatch('deletePartInProject', payload).then(response => {
                        // Deleted in Frontend array
                        this.newPart.splice(index, 1);

                        this.$emit('updateGncMessage', {
                            type:'success',
                            message:response.data.message
                        });
                    }).catch( errors => {
                        console.log(errors);
                        this.$emit('updateGncMessage', {
                            type:'error',
                            message:errors.message
                        });
                    });
                }
            },

            async changeTaskDate(){
                if(this.dateTask && this.dateTask.length > 0){
                    let payload = {
                        nonConformiteId:this.nonConformite.id,
                        projectNumber:this.nonConformite.projet,
                        dateTask:this.dateTask
                    };

                    //Update in Syteline
                    this.$store.dispatch('updateTaskDateInProject', payload).then(response => {
                        this.$emit('updateGncMessage', {
                            type:'success',
                            message:response.data.message
                        });
                    }).catch( error => {
                        this.$emit('updateGncMessage', {
                            type:'error',
                            message:error.message
                        });
                    });
                }
                else {
                    alert("Vous devez choisir une date à modifier pour la tâche avant de la changer!"); 
                }
            },

            ncNeedLaborOnChange(){
                //Make toggle true/false on this.isNcNeedLabor
                console.log(this.nonConformite.isNcNeedLabor);
            },

            checkUp(){
                this.$emit('checkUp');
            },
        },
        watch: {
            newSytelineProjectNumber(newVal) {
                if(newVal !== null){
                    this.nonConformite.projet = newVal;
                }
            },
            isSpinnerLoading(newVal) {
                this.isLoading = !!newVal;
            },
            isSpinnerLoading2(newVal) {
                this.isLoading2 = !!newVal;
            },
            resolutionNouvelUtilisateur(newValue) {
                if (!newValue) {
                    this.selectionMade = false;
                }
            },
        },
    }
</script>

<style scoped>
.v-autocomplete .v-input__append-inner .v-icon.v-icon--link {
    display: none;
}

.parts-box {
    height: 200px;
    border-radius: 8px;
    border:2px solid black;
    overflow-y: auto;
    background-color: black;
    font-weight: bolder;
    margin: 4px 8px;
}

.parts-box-header {
    display: inline-flex;
    width:100%;
    background-color: #9f9d9d;
    border-bottom: 2px solid black;
}

.parts-box-row {
    display: inline-flex;
    width:100%;
    color:greenyellow;
}

.spinner {
    color: blue;
    position: absolute;
    top: 15%;
    right: 50%;
}
</style>
